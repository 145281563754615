import { useState } from 'react';
import AddProfessioanlPageBasicinfo from './add-professionalpage-basic-info/add-professionalpage-basic-info';
import AddProfessionalPageService from './add-professionalpage-serivice/add-professionalpage-serivice';
import AddProfessionalPageServiceArea from './add-professionalpage-service-area/add-professionalpage-service-area';
import './create-professional-page-container.css'
import { Footer, Header } from '../../../layouts';
import AddProfessionalPageWorkHour from './add-professionalpage-workhour/add-professional-page-work-hour';
import AddProfessionalPageContavtInfo from './add-professionalpage-contact-info/add-professionalpage-contact-info';

const CreateProfessionalPageContainer = () => {

    const [activeStep, setActiveStep] = useState(1);
    const [professionalPageFormData, setProfessionalPageFormData] = useState<any>(new FormData());


    const handleNextStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }


    const handlePreviousStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }

    const refreshSteps = (stepperId: number) => {
        setActiveStep(stepperId);
    }

    const tab = () => {
        setActiveStep(1);
    }
    const tab2 = () => {
        setActiveStep(2);
    }
    const tab3 = () => {
        setActiveStep(3);
    }
    const tab4 = () => {
        setActiveStep(4);
    }
    const tab5 = () => {
        setActiveStep(5);
    }

    return <>
        <Header></Header>
        {/* <div className="container mt-5"> */}


        {activeStep === 1 && (
            <AddProfessioanlPageBasicinfo professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}

        {/* {activeStep != 2 &&   <div className=' header-inpage ' onClick={tab2}>
                <h5 className='p-3  header-style '> Step 2 </h5>
            </div>} */}

        {activeStep === 2 && (
            <AddProfessionalPageWorkHour professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}
        {/* {activeStep != 3 &&   <div className=' header-inpage ' onClick={tab3}>
                <h5 className='p-3  header-style '> Step 3 </h5>
            </div>} */}
        {activeStep === 3 && (
            <AddProfessionalPageServiceArea professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}
        {/* {activeStep != 4 &&   <div className=' header-inpage ' onClick={tab4}>
                <h5 className='p-3  header-style '> Step 4 </h5>
            </div>} */}
        {activeStep === 4 && (
            <AddProfessionalPageService professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}
        {/* {activeStep != 5 &&   <div className=' header-inpage ' onClick={tab5}>
                <h5 className='p-3  header-style '> Step 5 </h5>
            </div>} */}
        {activeStep === 5 && (
            <AddProfessionalPageContavtInfo professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} />
        )}

        {/* </div> */}
        <div className="d-none d-lg-block">
            <Footer></Footer>
        </div>
    </>
}
export default CreateProfessionalPageContainer;