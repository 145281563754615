import HttpClientWrapper from "../../http-client-wrapper";
import { FavouritePlanPayload } from "./plan-status-payload";


class PlanStatusApiService {
    
    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }

    saveFavouritePlan = async (payload: FavouritePlanPayload) => {
        try {
            let data: any = await this.httpClientWrapper.post('/v1/newPlanFavourite/favouritePlan', payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    checkPlanStatusByMobileNumber = async(mobileNo:any) =>{
        try{
            let data:any = await this.httpClientWrapper.get('/v1/customPlans/customPlan/'+ mobileNo);

            return data;
        } catch (error) {
            throw error;
        }

    }

}
export default PlanStatusApiService;