import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../create-professional-page-container.css';
import ValidationError from "../../../../common/validation-error";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ProfessionalPageWorkHourImage from '../../../../assets/professional-page-2.jpeg';
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";

const EditProfessionalPageWorkHour = ({ professionalPageId, professionalPageFormData, onPreviousCallback, onNextCallback }) => {

    const tabNumber: number = 2;
    const [selectedChips] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [processing, setProcessing] = useState(false);
    const desiredOrder = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const [timeOptions, setTimeOptions] = useState([]);

    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = hour.toString().padStart(2, "0");
                const formattedMinute = minute.toString().padStart(2, "0");
                options.push(`${formattedHour}:${formattedMinute}`);
            }
        }
        setTimeOptions(options);
    };

    const renderTimeOptions = () => {
        return timeOptions.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ));
    };
    const [showSwitchStates, setShowSwitchStates] = useState({
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
    });

    const handleChipSelect = (dayOfWeek: any) => {
        if (!selectedDays.some((selectedDay) => selectedDay.dayOfWeek === dayOfWeek)) {
            setSelectedDays([
                ...selectedDays,
                {
                    dayOfWeek,
                    startTime: '',
                    endTime: '',
                    professionalPageWorkHourId: '',
                    professionalPageId: ''
                }
            ]);
        }
    };
    const [switchStates, setSwitchStates] = useState({
        SUNDAY: true,
        MONDAY: true,
        TUESDAY: true,
        WEDNESDAY: true,
        THURSDAY: true,
        FRIDAY: true,
        SATURDAY: true,
    });
    const handleAddTimes = (index) => {
        const newTimeEntry = {
            ...selectedDays[index],
            startTime: '',
            endTime: '',
            professionalPageWorkHourId: '',
            professionalPageId: '',
            addTimes: true
        };
        const updatedSelectedDays = [...selectedDays];
        updatedSelectedDays.splice(index + 1, 0, newTimeEntry);
        setSelectedDays(updatedSelectedDays);
    };

    const handleCancel = (index) => {
        const updatedSelectedDays = [...selectedDays];
        updatedSelectedDays.splice(index, 1);
        setSelectedDays(updatedSelectedDays);
    };
    const handleDaySwitchChange = (day) => {
        setSwitchStates((prevSwitchStates) => {
            // Retain the existing values when closing the switch
            setSelectedDays((prevSelectedDays) => {
                const updatedSelectedDays = prevSelectedDays.map((d) => {
                    if (d.dayOfWeek === day) {
                        // Clear both startTime and endTime when the switch state changes to "Closed"
                        return {
                            ...d,
                            startTime: prevSwitchStates[day] ? '' : '',
                            endTime: prevSwitchStates[day] ? '' : '',
                        };
                    } else {
                        return d;
                    }
                });
                return updatedSelectedDays;
            });

            // Update the switch state
            return {
                ...prevSwitchStates,
                [day]: !prevSwitchStates[day],
            };
        });
    };

    const calculateTotalHours = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return '00:00';
        }
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);
        let totalMinutes = (endHour - startHour) * 60 + (endMinute - startMinute);
        if (totalMinutes < 0) {
            totalMinutes += 24 * 60;
        }
        const hours = Math.floor(totalMinutes / 60).toString().padStart(2, "0");
        const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    const sortedSelectedDays = selectedDays.sort((a, b) => {
        return desiredOrder.indexOf(a.dayOfWeek) - desiredOrder.indexOf(b.dayOfWeek);
    });

    const getChipClassName = (day: string) => {
        let classNames = 'chip ' + day;
        if (selectedChips.includes(day)) {
            classNames += ' selected';
        }
        return classNames;
    };

    const preview = () => {
        onPreviousCallback(tabNumber - 1)

    }

    const validateAndSaveWorkHourFormData = () => {
        try {
            validateWorkHourFormData();
            if (professionalPageFormData.has('pageWorkHoursStr')) {
                professionalPageFormData.delete('pageWorkHoursStr');
            }
            professionalPageFormData.append('pageWorkHoursStr', JSON.stringify(hours));
            let pageWorkHoursStr = JSON.stringify(hours);
            console.log('pageWorkHoursStr', pageWorkHoursStr);
            onNextCallback((tabNumber + 1));
        } catch (error: any) {
            throw error;
        }
    }

    const validateWorkHourFormData = (): void => {
        let errors: any[] = [];
        const selectedWorkHours = JSON.stringify(selectedDays);

        const hasNonEmptyTime = selectedDays.some(
            (day) => day.startTime !== '' || day.endTime !== ''
        );

        if (hours.every(day => !day.open)) {
            let error: any = {};
            error.validationCode = 'workhours';
            error.message = 'Work hour required';
            errors.push(error);
            toast.error(error.message, { containerId: 'TR' });
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    };

    const highlightWorkHourFormData = () => {
        let formData: FormData = professionalPageFormData;
        if (!formData.has('pageWorkHoursStr')) {
            return;
        }
        let pageWorkHours: any[] = JSON.parse(formData.get('pageWorkHoursStr').toString());
        // const groupedWorkHours = pageWorkHours.reduce((acc, item) => {
        //     if (!acc[item.dayOfWeek]) {
        //         acc[item.dayOfWeek] = [];
        //     }
        //     acc[item.dayOfWeek].push({ id: item.id, startTime: item.startTime, endTime: item.endTime });
        //     return acc;
        // }, {});
        // const defaultHours = hours.map((defaultHour) => {
        //     const timeSlots = groupedWorkHours[defaultHour.dayOfWeek] || [];
        //     return {
        //         dayOfWeek: defaultHour.dayOfWeek,
        //         open: timeSlots.length > 0,
        //         timeSlots: timeSlots,
        //     };
        // });
        // setHours(defaultHours);
        const isProcessedFormat = pageWorkHours.every((item) => 'open' in item && 'timeSlots' in item);
        let groupedWorkHours;
        if (!isProcessedFormat) {
            groupedWorkHours = pageWorkHours.reduce((acc, item) => {
                if (!acc[item.dayOfWeek]) {
                    acc[item.dayOfWeek] = [];
                }
                acc[item.dayOfWeek].push({
                    id: item.id,
                    startTime: item.startTime,
                    endTime: item.endTime,
                });
                return acc;
            }, {});
        } else {
            groupedWorkHours = pageWorkHours.reduce((acc, item) => {
                acc[item.dayOfWeek] = item.timeSlots;
                return acc;
            }, {});
        }
        const defaultHours = hours.map((defaultHour) => {
            const timeSlots = groupedWorkHours[defaultHour.dayOfWeek] || [];
            return {
                dayOfWeek: defaultHour.dayOfWeek,
                open: timeSlots.length > 0,
                timeSlots: timeSlots,
            };
        });
        setHours(defaultHours);
    }

    const handleStartTimeChange = (newStartTime, index) => {
        setSwitchStates((prevSwitchStates) => {
            const updatedSwitchStates = {
                ...prevSwitchStates,
                [selectedDays[index].dayOfWeek]: newStartTime !== '' || selectedDays[index].endTime !== '',
            };

            setSelectedDays((prevSelectedDays) => {
                const updatedSelectedDays = prevSelectedDays.map((day, i) => {
                    if (i === index) {
                        return {
                            ...day,
                            startTime: updatedSwitchStates[day.dayOfWeek] ? newStartTime : '',
                            endTime: updatedSwitchStates[day.dayOfWeek] ? day.endTime : '',
                        };
                    }
                    return day;
                });
                return updatedSelectedDays;
            });

            return updatedSwitchStates;
        });
    };

    const handleEndTimeChange = (newEndTime, index) => {
        setSwitchStates((prevSwitchStates) => {
            const updatedSwitchStates = {
                ...prevSwitchStates,
                [selectedDays[index].dayOfWeek]: newEndTime !== '' || selectedDays[index].startTime !== '',
            };

            setSelectedDays((prevSelectedDays) => {
                const updatedSelectedDays = prevSelectedDays.map((day, i) => {
                    if (i === index) {
                        // Check if endTime is empty, then set startTime to empty as well
                        return {
                            ...day,
                            endTime: newEndTime,
                            startTime: newEndTime !== '' ? day.startTime : '',
                        };
                    }
                    return day;
                });
                return updatedSelectedDays;
            });

            return updatedSwitchStates;
        });
    };


    useEffect(() => {
        window.scrollTo(0, 0);
        highlightWorkHourFormData();
        generateTimeOptions();
    }, []);

    interface TimeSlot {
        startTime: string;
        endTime: string;
    }

    interface WorkHours {
        dayOfWeek: string;
        open: boolean;
        timeSlots: TimeSlot[];
    }

    const [hours, setHours] = useState<WorkHours[]>([
        { dayOfWeek: 'SUNDAY', open: false, timeSlots: [] },
        { dayOfWeek: 'MONDAY', open: false, timeSlots: [] },
        { dayOfWeek: 'TUESDAY', open: false, timeSlots: [] },
        { dayOfWeek: 'WEDNESDAY', open: false, timeSlots: [] },
        { dayOfWeek: 'THURSDAY', open: false, timeSlots: [] },
        { dayOfWeek: 'FRIDAY', open: false, timeSlots: [] },
        { dayOfWeek: 'SATURDAY', open: false, timeSlots: [] },
    ]);


    const handleToggle = (index: number) => {
        const updatedHours = [...hours];
        const hour = updatedHours[index];
        if (!hour.open) {
            hour.timeSlots = [{ startTime: "08:00", endTime: "17:00" }];
        } else {
            hour.timeSlots = [];
        }
        hour.open = !hour.open;
        setHours(updatedHours);
    };

    const handleTimeChange = (dayIndex: number, slotIndex: number, time: "startTime" | "endTime", value: string) => {
        const updatedHours = [...hours];
        updatedHours[dayIndex].timeSlots[slotIndex][time] = value;
        setHours(updatedHours);
    };

    const handleSubmit = () => {
        if (hours.every(day => !day.open)) {
            let pageWorkHoursStr = JSON.stringify(hours);
            console.log('Updated Hours:', pageWorkHoursStr);
        }
    };

    const addHours = (index: number) => {
        const updatedHours = [...hours];
        updatedHours[index].timeSlots.push({ startTime: '08:00', endTime: '17:00' });
        setHours(updatedHours);
    };

    const removeHours = (dayIndex: number, slotIndex: number) => {
        const updatedHours = [...hours];
        updatedHours[dayIndex].timeSlots.splice(slotIndex, 1);
        setHours(updatedHours);
    };

    return <>
        <section className="d-flex justify-content-center pb-0">
            <Row className="container">
                <Col lg={6} className="d-none d-lg-flex align-self-center">
                    <img src={ProfessionalPageWorkHourImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </Col>
                <Col lg={6} className="mt-3">
                    <Row className="g-3">
                        <Col lg={12} className="">
                            <h3 className="text-center">Tell us what time will you be available ?</h3>
                        </Col>
                        <Col lg={12} className="">
                            <span className='fs-5'>This helps customers find you if they are looking for a business like yours.</span>
                        </Col>
                        <Col lg={12} className="">
                            {hours.map((hour, index) => (
                                <Row key={index} className="mb-3 align-items-center g-2">
                                    <Col md={2} className="text-start col">
                                        <strong>{hour.dayOfWeek}</strong>
                                    </Col>
                                    <Col md={2} className="text-center col">
                                        <Form.Check
                                            type="switch"
                                            id={`switch-${index}`}
                                            checked={hour.open}
                                            onChange={() => handleToggle(index)}
                                        />
                                        <span>{hour.open ? "Open" : "Closed"}</span>
                                    </Col>
                                    <Col md={8}>
                                        {hour.open &&
                                            hour.timeSlots.map((slot, slotIndex) => (
                                                <Row className="align-items-center mb-2" key={slotIndex}>
                                                    <Col md={4} className="col">
                                                        <Form.Control
                                                            type="time"
                                                            value={slot.startTime}
                                                            onChange={(e) =>
                                                                handleTimeChange(index, slotIndex, "startTime", e.target.value)
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} className="col">
                                                        <Form.Control
                                                            type="time"
                                                            value={slot.endTime}
                                                            onChange={(e) =>
                                                                handleTimeChange(index, slotIndex, "endTime", e.target.value)
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md={4} className="text-start col">
                                                        {slotIndex === 0 ? (
                                                            <a href="#"
                                                                style={{ textDecoration: "none" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    addHours(index);
                                                                }}
                                                            >
                                                                ADD HOURS
                                                            </a>
                                                        ) : (
                                                            <a href="#"
                                                                style={{ textDecoration: "none" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    removeHours(index, slotIndex);
                                                                }}
                                                            >
                                                                CANCEL
                                                            </a>
                                                        )}
                                                    </Col>
                                                </Row>
                                            ))}
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                        {/* <div className="row">
                            <div className="col-sm-12 mb-2">
                                <hr />
                            </div>
                            <div className="col-sm-12">
                                <span className='text-start fw-bold'>Select Working Days</span>
                            </div>
                            <div className="col-sm-12">
                                <div className="chips-container">
                                    <span
                                        className={getChipClassName('Sunday')}
                                        onClick={() => handleChipSelect('SUNDAY')}>Sunday
                                    </span>
                                    <span
                                        className={getChipClassName('Monday')}
                                        onClick={() => handleChipSelect('MONDAY')}>Monday
                                    </span>
                                    <span
                                        className={getChipClassName('Tuesday')}
                                        onClick={() => handleChipSelect('TUESDAY')}>Tuesday
                                    </span>

                                    <span
                                        className={getChipClassName('Wednesday')}
                                        onClick={() => handleChipSelect('WEDNESDAY')}
                                    >Wednesday</span>
                                    <span
                                        className={getChipClassName('Thursday')}
                                        onClick={() => handleChipSelect('THURSDAY')}>Thursday
                                    </span>
                                    <span
                                        className={getChipClassName('Friday')}
                                        onClick={() => handleChipSelect('FRIDAY')}>Friday
                                    </span>
                                    <span
                                        className={getChipClassName('Saturday')}
                                        onClick={() => handleChipSelect('SATURDAY')}>Saturday
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-12 overflow">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Total Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedSelectedDays.map((dayObj, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {switchStates[dayObj.dayOfWeek] && <>{dayObj.dayOfWeek}</>}
                                                </td>
                                                <td>
                                                    {switchStates[dayObj.dayOfWeek] && (
                                                        <select
                                                            className="form__input input_time p-1"
                                                            value={dayObj.startTime}
                                                            onChange={(e) => {
                                                                handleStartTimeChange(e.target.value, index);
                                                            }}
                                                        >
                                                            <option value="" disabled>
                                                                Open
                                                            </option>
                                                            {renderTimeOptions()}
                                                        </select>
                                                    )}
                                                </td>
                                                <td>
                                                    {switchStates[dayObj.dayOfWeek] && (
                                                        <select
                                                            className="form__input input_time p-1"
                                                            value={dayObj.endTime}
                                                            onChange={(e) => {
                                                                handleEndTimeChange(e.target.value, index);
                                                            }}
                                                        >
                                                            <option value="" disabled>
                                                                Close
                                                            </option>
                                                            {renderTimeOptions()}
                                                        </select>
                                                    )}
                                                </td>
                                                <td>
                                                    {switchStates[dayObj.dayOfWeek] && (
                                                        <>
                                                            {calculateTotalHours(dayObj.startTime, dayObj.endTime)}
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className='d-flex'>
                                                        {switchStates[dayObj.dayOfWeek] && (
                                                            <>
                                                                {console.log("addTimes value:", dayObj.addTimes)}
                                                                {index === sortedSelectedDays.findIndex((d) => d.dayOfWeek === dayObj.dayOfWeek) && (
                                                                    <span className="add-times" onClick={() => handleAddTimes(index)}>
                                                                        Add Times
                                                                    </span>
                                                                )}
                                                                {index > sortedSelectedDays.findIndex((d) => d.dayOfWeek === dayObj.dayOfWeek) && (
                                                                    <div>
                                                                        <IconButton onClick={() => handleCancel(index)}>
                                                                            <ClearOutlinedIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                        {index === sortedSelectedDays.findIndex((d) => d.dayOfWeek === dayObj.dayOfWeek) && (
                                                            <div>
                                                                {switchStates[dayObj.dayOfWeek] ? (
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={`custom-switch-${dayObj.dayOfWeek}`}
                                                                        label={switchStates[dayObj.dayOfWeek] ? 'Opened' : 'Closed'}
                                                                        onChange={() => handleDaySwitchChange(dayObj.dayOfWeek)}
                                                                        checked={switchStates[dayObj.dayOfWeek]}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        )}

                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                        <Col lg={12}>
                            <div className="d-flex justify-content-between align-items-center p-3">
                                <div>
                                    <button
                                        disabled={processing}
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={preview}
                                    >
                                        Previous
                                    </button>
                                    {processing && <Spinner animation="grow" variant="warning" className="ms-2" />}
                                </div>
                                <div>
                                    <button
                                        disabled={processing}
                                        className="btn btn-success"
                                        type="button"
                                        onClick={validateAndSaveWorkHourFormData}
                                    >
                                        Next
                                    </button>
                                    {processing && <Spinner animation="grow" variant="warning" className="ms-2" />}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    </>
}
export default EditProfessionalPageWorkHour;