import { Button, Card, Row } from "react-bootstrap";
import "../my-professional-pages/my-professional-pages.css";
import { Footer, Header } from "../../../layouts";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import StorageService from "../../../data/storage/storage-service";
import { useState, useEffect } from "react";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/avatar.png"
import ProfilePic from "../../../assets/profile-pic.jpeg"
import CoverImage from "../../../assets/coverImage.jpeg"

const MyProfessionalPages = () => {

    const professionalPageApiService = new ProfessionalPageApiService();
    const [data, setData] = useState<any>([]);
    let navigate: any = useNavigate();
    const [loading, setLoading] = useState(true);

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setData(res);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleViewButtonClick = (userId: number, id: number) => {
        // navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
        const combined = `userId=${userId}&id=${id}`;
        const encoded = btoa(combined);
        navigate(`/professional?v=${encoded}`);
        console.log(`View button clicked for userId: ${userId}, id: ${id}`);
    };

    useEffect(() => {
        getProfessionalPageDetails();
    }, []);

    return <>
        <div className="my_professional_page_background">
            <Header></Header>
            {loading ? (
                <div className="d-flex justify-content-center"
                    style={{ marginTop: '20%', marginBottom: '20%', background: 'white' }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="main_page container">
                        <Row>
                            <div className="sec-heading center mt-4 m-0 mb-3">
                                <h2>My Pages</h2>
                            </div>
                        </Row>
                        <Row className="d-flex justify-content-center mb-4">
                            {Array.isArray(data) && data.map((item: any, index: any) =>
                                <Card className="my_professional_page_card m-3 p-3">
                                    <div className="my_professional_page_banner">
                                        <img className="my_professional_page_cover_img"
                                            src={item.coverImageURL != null ? item.coverImageURL : CoverImage} />
                                    </div>
                                    <div className="round_profile_image ms-3">
                                        <img
                                            className="my_professional_page_profile_img"
                                            // src={item.profileImageUrl}
                                            src={item.profileImageUrl != null ? item.profileImageUrl : ProfilePic}
                                            alt="Profile"
                                            height={100}
                                        />
                                    </div>
                                    <Card.Body>
                                        <Row>
                                            <div className="d-flex justify-content-center my_professional_page_details" style={{ wordBreak: 'break-all' }}>{item.name}</div>
                                            <div className="d-flex justify-content-center my_professional_page_details">{item.professionTypeLabel}</div>
                                            <Button className="d-flex justify-content-center my_professional_page_view mt-4"
                                                onClick={() => handleViewButtonClick(item.createdBy, item.id)}>
                                                View
                                            </Button>
                                            <Row className="d-flex justify-content-center mt-3">
                                                <FacebookIcon className="my_professional_page_icon m-2" />
                                                <TwitterIcon className="my_professional_page_icon m-2" />
                                                <YouTubeIcon className="my_professional_page_icon m-2" />
                                                <InstagramIcon className="my_professional_page_icon m-2" />
                                                <PinterestIcon className="my_professional_page_icon m-2" />
                                            </Row>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}
                        </Row>
                    </div>
                    <Footer></Footer>
                </>
            )}
        </div >
    </>
}
export default MyProfessionalPages;