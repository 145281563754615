import { useCallback, useEffect, useRef, useState } from "react";
import './edit-professionalpage-contact-info.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FaPhone } from "react-icons/fa";
import Select from 'react-select';
import ProfessionalPageApiService from "../../../../data/api/services/professional-page/professional-page-api-service";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import { Link } from "@mui/material";
import { Button, Col, Modal, Nav, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ValidationError from "../../../../common/validation-error";
import { confirmAlert } from "react-confirm-alert";
import StorageService from "../../../../data/storage/storage-service";
import ProfessionalPageContactInfoImage from '../../../../assets/professional-page-5.jpeg';
import Cropper from "react-easy-crop";

const EditProfessionalPageContactInfo = ({ professionalPageId, professionalPageFormData, onPreviousCallback }) => {
    const tabNumber: number = 5;
    const [showNext, setShowNext] = useState(false);
    const [croppedImageSrc, setCroppedImageSrc] = useState<any>();
    const [cropCover, setCropCover] = useState({ x: 0, y: 0 });
    const [cropCoverConfirmed, setCropCoverConfirmed] = useState(false);

    const professionalPageApiservice = new ProfessionalPageApiService();
    const [countryOptions, setcountryOptions] = useState([]);
    const [nickNameValidationErrorMsg, setNickValidationNameErrorMsg] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showExtraFilters, setShowExtraFilters] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [showCroppedImage, setShowCroppedImage] = useState(true);
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youTube, setYouTube] = useState('');
    const [pinterest, setPinterest] = useState('');
    const [profileImage, setProfileImage] = useState<any>();
    const profileImageInput: any = useRef(null);
    const [coverImage, setCoverImage] = useState<any>();
    const coverImageInput: any = useRef(null);
    const [isNewProfileImage, setIsNewProfileImage] = useState("false");
    const [isNewCoverImage, setIsNewCoverImage] = useState("false");
    const [zoom, setZoom] = useState(1)
    const [cropConfirmed, setCropConfirmed] = useState(false);
    const [showProfileImageModal, setShowProfileImageModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [profileImageValidationErrorMsg, setProfileImageValidationErrorMsg] = useState(null);
    const [coverImageValidationErrorMsg, setCoverImageValidationErrorMsg] = useState(null);
    const [croppedCoverImageSrc, setCroppedCoverImageSrc] = useState<any>();
    const [countryCodeValidationErrorMsg, setCountryCodeValidationErrorMsg] = useState(null);
    const [mobileNumberValidationErrorMsg, setMobileNumberValidationErrorMsg] = useState(null);

    let navigate: any = useNavigate();

    const handleCountryChange = (cc: any) => {
        if (cc != null) {
            setSelectedCountry(cc);
            console.log(cc);
            setCountryCodeValidationErrorMsg(null);
        }
    };

    const dataURItoFiles = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // Create a Blob
        const blob = new Blob([ab], { type });

        // Create a File with the Blob and filename
        return new File([blob], filename, { type });
    };
    const dataURItoFile = (dataURI, type, filename) => {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // Create a Blob
        const blob = new Blob([ab], { type });

        // Create a File with the Blob and filename
        return new File([blob], filename, { type });
    };
    const handleMobileNumberOnChange = (mn: any) => {
        setMobileNumber(mn);
        setMobileNumberValidationErrorMsg(null);
    };

    const handleWebsiteOnChange = (ws: any) => {
        setWebsite(ws);
    };

    const handleFacebookOnChange = (fb: any) => {
        setFacebook(fb);
    }

    const handleTwitterOnChange = (tw: any) => {
        setTwitter(tw);
    }

    const handleInstagramOnChange = (ig: any) => {
        setInstagram(ig);
    }

    const handleYouTubeOnChange = (yt: any) => {
        setYouTube(yt);
    }
    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const handlePinterestOnChange = (pt: any) => {
        setPinterest(pt);
    }
    const [completedCrop, setCompletedCrop] = useState<Point>({
        x: 0,
        y: 0,

    });
    const validateContactInfoFormData = (): void => {
        let errors: any[] = [];

        if (!selectedCountry) {
            let error: any = {};
            error.validationCode = "countryIdStr";
            error.message = 'Country Code required';
            errors.push(error);
        }
        if (!mobileNumber) {
            let error: any = {};
            error.validationCode = "mobile";
            error.message = 'Mobile Number required';
            errors.push(error);
        }
        // if (!profileImage) {
        //     let error: any = {};
        //     error.validationCode = "profileImage";
        //     error.message = 'Profile Image required';
        //     errors.push(error);
        // }
        // if (!coverImage) {
        //     let error: any = {};
        //     error.validationCode = "coverImage";
        //     error.message = 'Cover Image required';
        //     errors.push(error);
        // }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const submitContactInfoFormData = async () => {
        try {
            validateAndSaveContactInfoFormData();
            let data = await professionalPageApiservice.updateProfessionalPage(professionalPageId, professionalPageFormData);
            navigate('/')
            console.log(data)
            toast.success('Successfully Updated Your ProfessionalPage Id as ' + data.id + '.', { containerId: 'TR' });
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const validateAndSaveContactInfoFormData = () => {
        try {
            validateContactInfoFormData();
            if (professionalPageFormData.has('countryIdStr')) {
                professionalPageFormData.delete('countryIdStr');
            }
            professionalPageFormData.append('countryIdStr', selectedCountry.countryCode);
            if (professionalPageFormData.has('mobile')) {
                professionalPageFormData.delete('mobile');
            }
            professionalPageFormData.append('mobile', mobileNumber);
            if (professionalPageFormData.has('website')) {
                professionalPageFormData.delete('website');
            }
            professionalPageFormData.append('website', website);
            if (croppedImageSrc) {
                if (professionalPageFormData.has("profileImage")) {
                    professionalPageFormData.delete('profileImage');
                }
                const filename = 'profileImage.jpg';
                const file = dataURItoFile(croppedImageSrc, 'image/jpeg', filename);
                professionalPageFormData.append('profileImage', file);
            } else {
                if (professionalPageFormData.has("profileImage")) {
                    professionalPageFormData.delete('profileImage');
                }
                if (profileImage?.file) {
                    professionalPageFormData.append('profileImage', profileImage.file);
                }
            }

            if (croppedCoverImageSrc) {
                if (professionalPageFormData.has("coverImage")) {
                    professionalPageFormData.delete('coverImage');
                }
                const filename = 'coverImage.jpg';
                const file = dataURItoFiles(croppedCoverImageSrc, 'image/jpeg', filename);
                professionalPageFormData.append('coverImage', file);
            } else {
                if (professionalPageFormData.has("coverImage")) {
                    professionalPageFormData.delete('coverImage');
                }
                if (coverImage?.file) {
                    professionalPageFormData.append('coverImage', coverImage.file);
                }
            }

            if (professionalPageFormData.has('facebook')) {
                professionalPageFormData.delete('facebook');
            }
            professionalPageFormData.append('facebook', facebook);

            if (professionalPageFormData.has('twitter')) {
                professionalPageFormData.delete('twitter');
            }
            professionalPageFormData.append('twitter', twitter);

            if (professionalPageFormData.has('instagram')) {
                professionalPageFormData.delete('instagram');
            }
            professionalPageFormData.append('instagram', instagram);

            if (professionalPageFormData.has('youTube')) {
                professionalPageFormData.delete('youTube');
            }
            professionalPageFormData.append('youTube', youTube);

            if (professionalPageFormData.has('pinterest')) {
                professionalPageFormData.delete('pinterest');
            }
            professionalPageFormData.append('pinterest', pinterest);
            if (professionalPageFormData.has("isNewProfileImage")) {
                professionalPageFormData.delete('isNewProfileImage');
            }
            professionalPageFormData.append('isNewProfileImage', isNewProfileImage.toString());
            if (professionalPageFormData.has("isNewCoverImage")) {
                professionalPageFormData.delete('isNewCoverImage');
            }
            professionalPageFormData.append('isNewCoverImage', isNewCoverImage.toString());

        } catch (error: any) {
            throw error;
        }
    }

    const onProfileImageUploadListener = () => {
        profileImageInput.current?.click();
    }
    const [zoomCover, setZoomCover] = useState(1)

    const handleCloseProfileImageModal = () => {
        setShowProfileImageModal(false);
    };
    const onProfessionalProfileImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let profileImage: any = {};
            profileImage.file = file;
            profileImage.imagePath = URL.createObjectURL(profileImage.file);
            setIsNewProfileImage("true");
            setProfileImage(profileImage);
            setProfileImageValidationErrorMsg('');

            // Update showProfileImageModal to true when a new image is selected
            setShowProfileImageModal(true);
        } else {
            setProfileImage(null);
            setProfileImageValidationErrorMsg('Invalid file. Please upload a valid image file.');
        }
    }

    const clearProfileImage = () => {
        setProfileImage(null);
    }

    const onCoverImageUploadListener = () => {
        coverImageInput.current?.click();
    }

    const onProfessionalCoverImageUploadListener = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size !== 0) {
            let coverImage: any = {};
            coverImage.file = file;
            coverImage.imagePath = URL.createObjectURL(coverImage.file);
            setIsNewCoverImage("true");
            setCoverImage(coverImage);
            setCoverImageValidationErrorMsg('');
            setShowModal(true);

        } else {
            setCoverImage(null);
            setCoverImageValidationErrorMsg('Invalid file. Please upload a valid image file.');
        }
    }


    const [showCoverCroppedImage, setShowCoverCroppedImage] = useState(true);
    const [completedCoverCrop, setCompletedCoverCrop] = useState<Point>({
        x: 0,
        y: 0,

    });
    const clearCoverImage = () => {
        setCoverImage(null);
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'countryIdStr') {
                setCountryCodeValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'mobile') {
                setMobileNumberValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'profileImage') {
                setProfileImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'coverImage') {
                setCoverImageValidationErrorMsg(errorMsg);
            }
        }
    }

    const highlightBasicInformationFormData = async () => {
        let formData: FormData = professionalPageFormData;
        if (formData.get('countryIdStr')) {
            const countryId = formData.get('countryIdStr').toString();
            const selectedLocationData = await professionalPageApiservice.getCountryById(countryId);
            setSelectedCountry(selectedLocationData);
        }
        if (formData.get('mobile')) {
            setMobileNumber(formData.get('mobile').toString());
        }
        if (formData.get('website')) {
            setWebsite(formData.get('website').toString());
        }
        if (formData.get('facebook')) {
            setFacebook(formData.get('facebook').toString());
        }
        if (formData.get('twitter')) {
            setTwitter(formData.get('twitter').toString());
        }
        if (formData.get('instagram')) {
            setInstagram(formData.get('instagram').toString());
        }
        if (formData.get('youTube')) {
            setYouTube(formData.get('youTube').toString());
        }
        if (formData.get('pinterest')) {
            setPinterest(formData.get('pinterest').toString());
        }
        hightlightProfessionalPageProfileImage();
        hightlightProfessionalPageCoverImage();
    }

    const hightlightProfessionalPageProfileImage = async () => {
        if (isNewProfileImage == 'true') {
            // setProfileImage();
            return;
        }
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let data = await professionalPageApiservice.getProfessionalPageById(professionalPageId);
            console.log(data);
            if (!data || !data.profileImageUrl) {
                return;
            }
            let profileImage: any = {};
            profileImage.imagePath = data.profileImageUrl;
            setProfileImage(profileImage);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }

    const hightlightProfessionalPageCoverImage = async () => {
        if (isNewCoverImage == 'true') {
            // setCoverImage();
            return;
        }

        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let data = await professionalPageApiservice.getProfessionalPageById(professionalPageId);
            console.log(data);
            if (!data || !data.coverImageURL) {
                return;
            }
            let coverImage: any = {};
            coverImage.imagePath = data.coverImageURL;
            setCoverImage(coverImage);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    const onLoad = useCallback((img) => {
        setCrop((prevCrop) => ({
            ...prevCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
        setCompletedCrop((prevCompletedCrop) => ({
            ...prevCompletedCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
    }, []);
    const handleImageCrop = async () => {
        if (completedCrop && profileImage) {
            try {
                const originalAspectRatio = profileImage.width / profileImage.height;
                const croppedAspectRatio = completedCrop.width / completedCrop.height;
                const desiredAspectRatio = 1;
                const aspectRatioTolerance = 1
                setCropConfirmed(true);
                setShowProfileImageModal(false);

            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowProfileImageModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowProfileImageModal(false);
        }
    };
    const onCropComplete = async (_, croppedAreaPixels) => {
        try {
            const croppedImageBase64 = await getCroppedImg(
                profileImage.imagePath, // Original image path
                croppedAreaPixels,
                1 // Output image quality, you can adjust this value
            );
            setCroppedImageSrc(croppedImageBase64);
        } catch (e) {
            console.error('Error creating cropped image:', e);
        }
    };

    type Point = {
        x: number;
        y: number;
        width?: number; // Add the width property
        height?: number; // Add the height property
    };
    const handleCoverImageCrop = async () => {
        if (completedCoverCrop && coverImage) {
            try {
                const originalAspectRatio = coverImage.width / coverImage.height;
                const croppedAspectRatio = completedCoverCrop.width / completedCoverCrop.height;
                const desiredAspectRatio = 1;
                const aspectRatioTolerance = 1
                setCropCoverConfirmed(true);
                setShowModal(false);

            } catch (error) {
                console.error('Error cropping image:', error.message);
                setShowModal(false);
            }
        } else {
            console.error('Error: Unable to crop image. Missing data.');
            setShowModal(false);
        }
    };
    const onLoads = useCallback((img) => {
        setCropCover((prevCrop) => ({
            ...prevCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
        setCompletedCoverCrop((prevCompletedCrop) => ({
            ...prevCompletedCrop,
            width: img.naturalWidth,
            height: img.naturalHeight,
        }));
    }, []);
    const getCroppedImg = (imagePath, crop, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;

            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = crop.width;
                    canvas.height = crop.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(
                        image,
                        crop.x, // X position on the source image
                        crop.y, // Y position on the source image
                        crop.width, // Width on the source image
                        crop.height, // Height on the source image
                        0, // X position on the canvas
                        0, // Y position on the canvas
                        crop.width, // Width on the canvas
                        crop.height // Height on the canvas
                    );

                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };

            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    }; const onCropCoverComplete = async (_, croppedPixels) => {
        try {
            const croppedImageBase = await getCroppedCoverImg(
                coverImage.imagePath, // Original image path
                croppedPixels,
                1 // Output image quality, you can adjust this value
            );
            setCroppedCoverImageSrc(croppedImageBase);
        } catch (e) {
            console.error('Error creating cropped image:', e);
        }
    };
    const getCroppedCoverImg = (imagePath, cropCover, quality = 0.92) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imagePath;

            image.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = cropCover.width;
                    canvas.height = cropCover.height;
                    const ctx = canvas.getContext('2d');

                    ctx.drawImage(
                        image,
                        cropCover.x, // X position on the source image
                        cropCover.y, // Y position on the source image
                        cropCover.width, // Width on the source image
                        cropCover.height, // Height on the source image
                        0, // X position on the canvas
                        0, // Y position on the canvas
                        cropCover.width, // Width on the canvas
                        cropCover.height // Height on the canvas
                    );

                    resolve(canvas.toDataURL('image/jpeg', quality));
                } catch (error) {
                    console.error('Error in getCroppedImg:', error);
                    reject(error);
                }
            };

            image.onerror = (error) => {
                console.error('Image failed to load in getCroppedImg:', error);
                reject(error);
            };
        });
    };
    const getTemplateData = async () => {
        let data = await professionalPageApiservice.getProfessionalPageTemplateData();
        setcountryOptions(data.countryData)
        highlightBasicInformationFormData();
        console.log(data);
    }

    const preview = () => {
        onPreviousCallback(tabNumber - 1);
        setShowNext(false);
    }
    const previous = () => {
        confirmAlert({
            title: "Alert Message",
            message: "The form you have filled will get lost in this page. Are you sure want to go previous?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => preview(),
                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    };

    const handleShowFilters = () => {
        setShowExtraFilters(true);
    };

    const handleHideFilters = () => {
        setShowExtraFilters(false);
    };

    const hasSocialMediaData = () => {
        return facebook || twitter || instagram || youTube || pinterest;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getTemplateData();
        if (hasSocialMediaData()) {
            setShowExtraFilters(true);
        }
    }, []);

    return <>
        <section className="d-flex justify-content-center pb-0">
            <Row className="container">
                <Col lg={6} className="d-none d-lg-flex align-self-center">
                    <img src={ProfessionalPageContactInfoImage} className='h-100 w-100' alt="google-bussiness" ></img>
                </Col>
                <Col lg={6} className="mt-3">
                    <Row className="g-3">
                        <Col lg={12} className="">
                            <h3 className="text-center">What contact details do you want to show to customers?</h3>
                        </Col>
                        <Col lg={12} className="">
                            <span className='fs-5'>Help customers get in touch by including this info on your listing Phone number.</span>
                        </Col>
                        <Col lg={12} className="">
                            <Row className="g-3">
                                <Col md={2}>
                                    <div className={`custom-input-field ${countryCodeValidationErrorMsg ? 'is-invalid' : ''}`}>
                                        <select
                                            className="form-control"
                                            value={selectedCountry?.countryCode || ""}
                                            onChange={(e) => {
                                                const selectedData = countryOptions.find(
                                                    (ptData) => ptData.countryCode === e.target.value
                                                );
                                                handleCountryChange(selectedData);
                                            }}
                                        >
                                            <option value="" disabled hidden>Select</option>
                                            {countryOptions.map((cData, index) => (
                                                <option key={cData.id} value={cData.countryCode}>
                                                    {cData.countryCode}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="fw-bold custom-input-field-placeholder">Code</span>
                                    </div>
                                    {countryCodeValidationErrorMsg && (
                                        <div className="invalid-feedback">Country code required</div>
                                    )}
                                </Col>

                                <Col md={10}>
                                    <div className={`custom-input-field ${mobileNumberValidationErrorMsg ? 'is-invalid' : ''}`}>
                                        <input type="number" placeholder=" "
                                            value={mobileNumber} onChange={e => { handleMobileNumberOnChange(e.target.value) }} />
                                        <span className="fw-bold custom-input-field-placeholder">Mobile No.</span>
                                    </div>
                                    {mobileNumberValidationErrorMsg && <div className="invalid-feedback px-2">Mobile No. required</div>}
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={12}>
                            <div className="custom-input-field">
                                <input type="text"
                                    placeholder=" "
                                    value={website !== null && website !== "null" ? website : ''}
                                    onChange={e => { handleWebsiteOnChange(e.target.value) }} />
                                <span className="fw-bold custom-input-field-placeholder">Website (optional)</span>
                            </div>
                        </Col>

                        <Col lg={12}>
                            <Row>
                                <Col lg={6} className="">
                                    <label className="mt-4">Upload Profile Image</label>
                                    <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                        <input
                                            type="file"
                                            className={profileImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '}
                                            ref={profileImageInput}
                                            onChange={onProfessionalProfileImageUploadListener}
                                        />
                                    </div>
                                    {!profileImage && (
                                        <div>
                                            <div className="mt-4 h-auto">
                                                <input
                                                    type="button"
                                                    className="form-control border-dark w-auto"
                                                    value="Upload Profile Image"
                                                    onClick={onProfileImageUploadListener}
                                                />
                                            </div>
                                            <FormFieldError errorMessage={profileImageValidationErrorMsg}></FormFieldError>
                                        </div>
                                    )}
                                    {profileImage ? (
                                        <Row className="mt-2">
                                            <Col md={12} className="d-flex justify-content-center">
                                                {croppedImageSrc ? (
                                                    <img
                                                        src={croppedImageSrc}
                                                        onLoad={(e) => onLoad(e.target)}
                                                        alt="Cropped Profile"
                                                        style={{ width: '100%', height: 200 }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={profileImage.imagePath}
                                                        width={'100%'}
                                                        height={200}
                                                        alt="Selected Profile"
                                                    />
                                                )}
                                            </Col>
                                            <Col md={12} className="">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm rounded-pill mt-2"
                                                    aria-label="Close"
                                                    onClick={() => clearProfileImage()}
                                                >
                                                    <span className="" aria-hidden="true">
                                                        Remove
                                                    </span>
                                                </button>
                                            </Col>
                                        </Row>
                                    ) : null}

                                </Col>

                                {showProfileImageModal && (
                                    <Modal show={showProfileImageModal} onHide={handleCloseProfileImageModal}>
                                        <Modal.Body>
                                            {profileImage && profileImage.imagePath && (
                                                <>
                                                    {!showCroppedImage && (
                                                        <img src={profileImage.imagePath} width={'100%'} height={200} alt="Selected Profile" />
                                                    )}
                                                    {showCroppedImage && (
                                                        <div className="row" style={{ position: 'relative', width: '100%', height: '450px' }}>
                                                            <Cropper
                                                                image={profileImage.imagePath}
                                                                crop={crop}
                                                                zoom={zoom}
                                                                onCropChange={setCrop}
                                                                onZoomChange={setZoom}
                                                                onCropComplete={onCropComplete}
                                                                aspect={1 / 1}
                                                            />
                                                            <div className="controls row">
                                                                <input
                                                                    type="range"
                                                                    value={zoom}
                                                                    min={1}
                                                                    max={3}
                                                                    step={0.1}
                                                                    aria-labelledby="Zoom"
                                                                    onChange={(e) => {
                                                                        setZoom(parseFloat(e.target.value));
                                                                    }}
                                                                    className="zoom-range"
                                                                />
                                                            </div>
                                                            {cropConfirmed && croppedImageSrc && (
                                                                <img src={croppedImageSrc} width={'100%'} height={'100%'} alt="Cropped Image" />
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {showCroppedImage && (
                                                <Button variant="primary" onClick={handleImageCrop}>
                                                    Confirm Crop
                                                </Button>
                                            )}
                                        </Modal.Footer>
                                    </Modal>
                                )}
                                <Col lg={6} className="">
                                    <label className="mt-4">Upload Cover Image</label>
                                    <div className="mt-4 h-auto" style={{ display: 'none' }}>
                                        <input type="file" className={coverImageValidationErrorMsg ? 'error-field input-edit ' : 'input-edit '} ref={coverImageInput} onChange={onProfessionalCoverImageUploadListener} />
                                    </div>
                                    {!coverImage && <div>
                                        <div className="mt-4 h-auto">
                                            <input type="button" className="form-control border-dark w-auto" value="Upload Cover Image" onClick={onCoverImageUploadListener} />
                                        </div>
                                        <FormFieldError errorMessage={coverImageValidationErrorMsg}></FormFieldError>
                                    </div>}
                                    {
                                        coverImage ? (
                                            <Row className="mt-2">
                                                <Col md={12} className="d-flex justify-content-center">
                                                    {croppedCoverImageSrc ? (
                                                        // Render the cropped image if croppedImageSrc has a value
                                                        <img
                                                            src={croppedCoverImageSrc}
                                                            onLoad={(e) => onLoads(e.target)}
                                                            alt="Cropped Profile"
                                                            style={{ width: '100%', height: 200 }}
                                                        />
                                                    ) : (
                                                        // Render the original profile image if croppedImageSrc is null
                                                        <img
                                                            src={coverImage.imagePath}
                                                            width={'100%'}
                                                            height={200}
                                                            alt="Selected Profile"
                                                        />
                                                    )}                                            </Col>
                                                <Col md={12} className="">
                                                    <button type="button" className="btn btn-danger btn-sm rounded-pill mt-2" aria-label="Close" onClick={() => clearCoverImage()}>
                                                        <span className="" aria-hidden="true">Remove</span>
                                                    </button>
                                                </Col>
                                            </Row>
                                        ) : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                        {showModal && (
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Body>
                                    {coverImage && coverImage.imagePath && (
                                        <>
                                            {/* Display the original selected image */}
                                            {!showCoverCroppedImage && (
                                                <img
                                                    src={coverImage.imagePath}
                                                    width={'100%'}
                                                    height={'100%'}
                                                    alt="Selected Profile"
                                                />
                                            )}

                                            {/* Display the Cropper only if the user clicked the "Crop Image" button */}
                                            {showCoverCroppedImage && (
                                                <div className="row" style={{ position: 'relative', width: '100%', height: '450px' }}>
                                                    <Cropper
                                                        image={coverImage.imagePath}
                                                        crop={cropCover}
                                                        zoom={zoomCover}
                                                        onCropChange={setCropCover}
                                                        onZoomChange={setZoomCover}
                                                        onCropComplete={onCropCoverComplete}
                                                        aspect={1 / 1}
                                                        cropSize={{ width: 400, height: 200 }}
                                                    />

                                                    <div className="controls row">
                                                        <input
                                                            type="range"
                                                            value={zoomCover}
                                                            min={1}
                                                            max={3}
                                                            step={0.1}
                                                            aria-labelledby="Zoom"
                                                            onChange={(e) => {
                                                                setZoom(parseFloat(e.target.value))
                                                            }}
                                                            className="zoom-range"
                                                        />
                                                    </div>
                                                    {/* Display the cropped image */}
                                                    {cropCoverConfirmed && croppedCoverImageSrc && (
                                                        <img
                                                            src={croppedCoverImageSrc}
                                                            width={'100%'}
                                                            height={'100%'}
                                                            alt="Cropped Image"
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>

                                    {showCoverCroppedImage && (
                                        <Button variant="primary" onClick={handleCoverImageCrop}>
                                            Confirm Crop
                                        </Button>
                                    )}


                                </Modal.Footer>
                            </Modal>
                        )}
                        <Col lg={12}>
                            <label className="d-flex justify-content-end p-2">
                                {!showExtraFilters && <Nav.Link className="underline-on-hover" onClick={handleShowFilters}>More Options</Nav.Link>}
                                {showExtraFilters && <Nav.Link className="underline-on-hover" onClick={handleHideFilters}>Less Options</Nav.Link>}
                            </label>
                        </Col>

                        {showExtraFilters && (
                            <>
                                <Col lg={12}>
                                    <Row className="g-3">
                                        <Col xl={6}>
                                            <div className="custom-input-field">
                                                <input type="text"
                                                    placeholder=" "
                                                    value={facebook !== null && facebook !== "null" ? facebook : ''}
                                                    onChange={e => { handleFacebookOnChange(e.target.value) }} />
                                                <span className="fw-bold custom-input-field-placeholder">Enter Facebook Link (optional)</span>
                                            </div></Col>
                                        <Col xl={6}>
                                            <div className="custom-input-field">
                                                <input type="text"
                                                    placeholder=" "
                                                    value={twitter !== null && twitter !== "null" ? twitter : ''}
                                                    onChange={e => { handleTwitterOnChange(e.target.value) }} />
                                                <span className="fw-bold custom-input-field-placeholder">Enter Twitter Link (optional)</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={12}>
                                    <Row className="g-3">
                                        <Col xl={6}>
                                            <div className="custom-input-field">
                                                <input type="text"
                                                    placeholder=" "
                                                    value={instagram !== null && instagram !== "null" ? instagram : ''}
                                                    onChange={e => { handleInstagramOnChange(e.target.value) }} />
                                                <span className="fw-bold custom-input-field-placeholder">Enter Instagram Link (optional)</span>
                                            </div></Col>
                                        <Col xl={6}>
                                            <div className="custom-input-field">
                                                <input type="text"
                                                    placeholder=" "
                                                    value={youTube !== null && youTube !== "null" ? youTube : ''}
                                                    onChange={e => { handleYouTubeOnChange(e.target.value) }} />
                                                <span className="fw-bold custom-input-field-placeholder">Enter YouTube Link (optional)</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={12}>
                                    <div className="custom-input-field">
                                        <input type="text"
                                            placeholder=" "
                                            value={pinterest !== null && pinterest !== "null" ? pinterest : ''}
                                            onChange={e => { handlePinterestOnChange(e.target.value) }} />
                                        <span className="fw-bold custom-input-field-placeholder">Enter Pinterest Link (optional)</span>
                                    </div>
                                </Col>
                            </>
                        )}
                        <Col lg={12}>
                            <div className="d-flex justify-content-between align-items-center p-3">
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={previous}
                                    >
                                        Previous
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-success"
                                        type="button"
                                        onClick={submitContactInfoFormData}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    </>
}
export default EditProfessionalPageContactInfo;