import { useEffect, useRef, useState } from "react";
import "./veedu-benefits.css"
import Header from "../../layouts/header/header";
import { useNavigate } from "react-router";
import BenefitApiService from "../../data/api/services/benefit-api/benefit-api-service";
import FreeQuestions from "../free-questions/free-questions";
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { SignupPayload, UpdateRegistrationPayload } from "../../data/api/services/register/signup-payload";
import { toast } from 'react-toastify';
import RegisterApiService from "../../data/api/services/register/register-api-service";
import { Button, Col, Modal } from "react-bootstrap";
import { AfterPaymentPayload, AuthPayload } from "../../data/api/services/auth/auth-payload";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SabPaisaApiService from "../../data/api/services/sabpaisa/sabpaisa-api-service";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OTPPageLogo from '../../assets/otp-page-logo.png'
import { Footer } from "../../layouts";
import SmsApiService from "../../data/api/services/sms/sms-api-service";

const VeeduBenefits = ({ registrationPayload, onPreviousCallback }) => {

  let navigate: any = useNavigate();
  const [selectedColumn, setSelectedColumn] = useState(null);
  const benefitApiService = new BenefitApiService();
  const [benifitData, setBenifitData] = useState<any>([]);
  const [priceType, setPriceType] = useState('');
  const [membershipPlanData, setMembershipPlanData] = useState<any>([]);
  const [membershipTypes, setMembershipTypes] = useState<any>([]);
  const [isFirstDataSelected, setIsFirstDataSelected] = useState(false);
  const [userType, setUserType] = useState("");
  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState<any>([]);
  const [encData, setEncData] = useState('');
  const [clientCode, setClientCode] = useState('');
  const registerService: RegisterApiService = new RegisterApiService();
  const authService: AuthApiService = new AuthApiService();
  const smsService : SmsApiService = new SmsApiService();
  const sabPaisaApiService = new SabPaisaApiService();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [mobileValidationError, setMobileNoValidationError] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [email, setEmail] = useState(registrationPayload?.email);
  const [mobile, setMobile] = useState(registrationPayload?.mobile);
  const [showMobileAndEmail, setShowMobileAndEmail] = useState(false);
  const handleCloseMobileAndEmail = () => setShowMobileAndEmail(false);
  const handleShowMobileAndEmail = () => setShowMobileAndEmail(true);
  const [otpDigits, setOtpDigits] = useState(['', '', '', '']);
  const inputRefs = useRef([]);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [otp, setOtp] = useState('');
  const [showOTPDialog, setShowOTPDialog] = useState(false);
  const handleCloseOTPDialog = () => setShowOTPDialog(false);
  const handleShowOTPDialog = () => setShowOTPDialog(true);
  const [loading, setLoading] = useState(false);

  const handleRadioChange = (event: any) => {
    console.log("SDFGHJK", event);
    const newValue = event.target.value;
    setPriceType(newValue);
  };

  const getTemplateData = async () => {
    try {
      let data = await benefitApiService.getTemplateData();
      setMembershipTypes(data.membershipTypeData);
      setBenifitData(data.memberShipBenefitData);
      let plan = await benefitApiService.getMemberShipPlans()
      setMembershipPlanData(plan);
      if (registrationPayload?.userType === 'ROLE_VIEW_USER') {
        setSelectedMembershipPlan(plan[0]);
        setIsFirstDataSelected(true);
      }
      if (registrationPayload?.userType === 'ROLE_PRO_USER') {
        setSelectedMembershipPlan(plan[1]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getLogin = async () => {
    const numbers = JSON.parse(window.localStorage.getItem('number'));
    if (numbers.logInType == "NORMAL") {
      executeLoginApi();
    } else {
      executeSocialLogInApi();
    }
  }

  const handleColumnSelection = (column: any) => {
    setIsFirstDataSelected(column.orderId === membershipPlanData[0].orderId);
    setSelectedColumn(column.orderId);
    if (column.orderId === 1) {
      setUserType("ROLE_VIEW_USER");
    }
    if (column.orderId === 2) {
      setUserType("ROLE_PRO_USER");
      setSelectedMembershipPlan(membershipPlanData[1]);
    }
    if (column.orderId === 3) {
      setUserType("ROLE_PRO_USER");
      setSelectedMembershipPlan(membershipPlanData[2]);
    }
    setSelectedMembershipPlan(column);
  };

  const validateUserType = () => {
    let isFormValid = true;
    if (!userType) {
      toast.error("Select your plan", { containerId: 'TR' });
      isFormValid = false;
    }
    return isFormValid;
  }

  const executeRegisterApi = async () => {
    try {
      if (validateUserType()) {
        setLoading(true);
        if (registrationPayload) {
          if (registrationPayload.logInType == "NORMAL") {
            let payload: SignupPayload = {
              firstName: registrationPayload.firstName,
              lastName: registrationPayload.lastName,
              email: registrationPayload.email,
              mobile: registrationPayload.mobile,
              password: registrationPayload.password,
              userType: userType,
              locationId: registrationPayload.locationId,
              otp: registrationPayload.otp,
              membershipBenefitMapperId: selectedMembershipPlan.id,
              amount: selectedMembershipPlan.amount,
              promoCode: registrationPayload.promoCode,
              logInType: registrationPayload.logInType,
              professionTypeId: registrationPayload.professionTypeId
            };
            if (payload.userType == 'ROLE_PRO_USER') {
              let userData = await registerService.register(payload);
              window.localStorage.setItem('number', JSON.stringify(payload));
              getSabPaisaResponse(payload);
            } else {
              let userData = await registerService.register(payload);
              window.localStorage.setItem('number', JSON.stringify(payload));
              getLogin();
            }
          } else {
            let url = registrationPayload.id;
            let payload: UpdateRegistrationPayload = {
              id: registrationPayload.id,
              firstName: registrationPayload.firstName,
              lastName: "",
              email: registrationPayload.email,
              mobile: registrationPayload.mobile,
              password: "",
              userType: userType,
              locationId: registrationPayload.locationId,
              membershipBenefitMapperId: selectedMembershipPlan.id,
              amount: selectedMembershipPlan.amount,
              promoCode: registrationPayload.promoCode,
              logInType: registrationPayload.logInType,
              professionTypeId: registrationPayload.professionTypeId
            };
            if (!payload.mobile) {
              setLoading(false);
              handleShowMobileAndEmail();
            } else {
              if (payload.userType == 'ROLE_PRO_USER') {
                let userData = await authService.updateSocialLogInUser(payload.id, payload);
                window.localStorage.setItem('number', JSON.stringify(payload));
                getSabPaisaResponse(payload);
              } else {
                let userData = await authService.updateSocialLogInUser(url, payload);
                window.localStorage.setItem('number', JSON.stringify(payload));
                getLogin();
              }
            }
          }
        } else {
          navigate('/register');
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [shouldSubmit, setShouldSubmit] = useState(false);

  const getSabPaisaResponse = async (registrationPayload) => {
    try {
      let url = `?payerName=${registrationPayload.firstName}&payerEmail=${registrationPayload.email}
      &payerMobile=${registrationPayload.mobile}&amount=${registrationPayload.amount}
      &accessPageFrom=${'REGISTER_PAGE_MEMBERSHIP'}&logInType=${registrationPayload.logInType}`;
      let data = await sabPaisaApiService.getSabPaisaResponse(url);
      console.log(data);
      setEncData(data.encData);
      setClientCode(data.clientCode);
      setShouldSubmit(true);
    } catch (error) {
      if (error.response && error.response.data) {
        console.error('Error response data:', error.response.data);
      } else {
        console.error('Error fetching SabPaisa response:', error.message);
      }
    }
  };

  const handleSubmit = () => {
    const form = document.getElementById('sabPaisaForm') as HTMLFormElement;
    if (form) {
      form.submit();
    }
  };

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit();
    }
  }, [encData, clientCode]);

  const executeLoginApi = async () => {
    const numbers = JSON.parse(window.localStorage.getItem('number'));
    try {
      let payload: AuthPayload = { mobile: numbers.mobile, password: numbers.password, roleCode: numbers.userType };
      await authService.doLogin(payload);
      toast.success('Successfully Registered', { containerId: 'TR' });
      navigate('/');
    } catch (error) {
      console.log("LoginPage Error");
      console.log(error);
    }
  };

  const executeSocialLogInApi = async () => {
    const numbers = JSON.parse(window.localStorage.getItem('number'));
    try {
      let payload: AfterPaymentPayload = {
        userName: numbers.email,
        logInType: numbers.logInType,
        roleCode: numbers.userType
      };
      await authService.afterPaymentLogIn(payload);
      toast.success('Successfully Registered', { containerId: 'TR' });
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getTemplateData();
    // if (registrationPayload?.userType === 'ROLE_VIEW_USER') {
    //   setSelectedColumn(1);
    // }
    // if (registrationPayload?.userType === 'ROLE_PRO_USER') {
    //   setSelectedColumn(2);
    // }
  }, []);

  const handleMobileNumberChange = (value: any) => {
    const formattedValue = value.replace(/\D/g, '').slice(0, 10);
    setMobile(formattedValue);
    setMobileNoValidationError(false);
  };

  const validateMobile = () => {
    let isFormValid = true;
    if (!mobile) {
      setMobileNoValidationError(true);
      isFormValid = false;
    }
    if (mobile) {
      if (mobile.length <= 9) {
        toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
        isFormValid = false;
      }
    }
    return isFormValid;
  }

  const handleModalSubmit = () => {
    if (validateMobile()) {
      checkUser(registrationPayload);
    }
  };

  const checkUser = async (payload) => {
    try {
      let url = `?mobileNo=${mobile}&email=${payload.email}&logInType=${payload.logInType}`;
      let data = await authService.fetchUserByMobileNoEmailLogInTypeAndUserType(url);
      console.log('dataaaaaa=>', data);
      if (!data) {
        generateOtp();
      } else {
        setShowMobileAndEmail(true);
        toast.error("Mobile No. Already Exist ", { containerId: 'TR' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOtp = async () => {
    try {
      let data = await smsService.generateOtp(mobile);
      setOtp(data.content);
      console.log(data.content);
      if (data.status == "Success") {
        setShowMobileAndEmail(false);
        handleShowOTPDialog();
      } else {
        setShowMobileAndEmail(true);
        toast.error("Try Again...", { containerId: 'TR' });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { containerId: 'TR' });
    }
  };

  const handleVerifyOtp = () => {
    const enteredOtp = otpDigits.join('');
    if (enteredOtp === otp) {
      registrationPayload.mobile = mobile;
      toast.success("OTP Verified Successfully", { containerId: 'TR' });
      handleCloseOTPDialog();
      executeRegisterApi();
    } else {
      toast.error('Incorrect OTP. Please try again.', { containerId: 'TR' });
    }
  };

  const displayLastTwoDigits = (number) => {
    const length = number.length;
    if (length < 2) {
      return number;
    }
    const firstTwoDigits = number.substring(0, 2);
    const lastTwoDigits = number.substring(number.length - 2);
    const maskedNumber = `${firstTwoDigits}******${lastTwoDigits}`;
    return maskedNumber;
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = value;
      setOtpDigits(newOtpDigits);
      if (index < otpDigits.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const resendOtp = async () => {
    try {
      let data = await smsService.generateOtp(mobile);
      setOtp(data.content);
      console.log(data.content);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, { containerId: 'TR' });
    }
  };

  const handleResendOtp = () => {
    setIsOtpSent(true);
    setSeconds(10);
    resendOtp();
  };

  return <>
    <div id="app">
      <Header></Header>
      <section className="bg-light section mt-5">
        <div className="col text-center">
          <div className="sec-heading">
            <h2 className="fw-bold">Pick Your Perfect plan</h2>
          </div>
        </div>
      </section>
      <div className="container-fluid bg-light table-container">
        <div className="row">
          <div className="col-sm-6 mx-auto">
            <div className="table-responsive d-flex">
              <table className="table">
                <thead className="fs-5">
                  <tr>
                    <th className="aaa top-header "> </th>
                  </tr>
                </thead>
                <tbody className="fs-5">
                  {benifitData.map((item, idx) => (
                    <tr key={idx}>
                      <td className="p-0 benefit-name">{item.name}</td>
                    </tr>
                  ))}
                  <tr>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex">
                {membershipPlanData.map((item, idx) => (
                  <table className="table" key={idx}>
                    <thead className="fs-5">
                      <tr>
                        <th
                          className={`aaa top-header ${selectedColumn === item.orderId ? 'selected' : ''}`}
                          onClick={() => handleColumnSelection(item)}
                        >
                          {item.memberShipTypeLabel}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="fs-5">
                      {item.memberShipBenefitDataList.map((item1, idx) => (
                        <tr key={idx}>
                          <td className={`aaa  ${selectedColumn === item.orderId ? 'selected' : ''}`}
                            onClick={() => handleColumnSelection(item)}>
                            {item1.hasBenefit ? <b><DoneOutlineIcon className="tik-icon" /></b> : <CloseIcon className="text-danger" />}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className={`aaa  ${selectedColumn === item.orderId ? 'selected' : ''}`}
                          onClick={() => handleColumnSelection(item)}>
                          ₹{item.amount}/-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
              </div>
            </div>
            <Modal show={show} onHide={handleClose} animation={false} size="lg" centered backdrop="static">
              <Modal.Header >
                <div className="popup">
                  <div className="my-4 d-flex">
                    <div>
                      <CheckCircleOutlineIcon style={{ fontSize: '8rem' }} className="  tick-icon" />
                    </div>
                    <label>
                      Congratulation <b> {registrationPayload?.firstName} {registrationPayload?.lastName} </b> ! You have signed up Successfully Wish You Have A Nice Experience
                      <div className="popup">
                        <Button variant="primary" onClick={getLogin}>
                          Continue Your Journey
                        </Button>
                      </div>
                    </label>
                  </div>
                </div>
              </Modal.Header>
            </Modal>
            <div className="row">
              <div className="d-flex justify-content-center mt-2 mb-2">
                <button type="button" onClick={() => executeRegisterApi()} className="btn btn-theme-light"
                  style={{ position: 'relative' }}
                  disabled={loading}>
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      }}>
                      <div className="loading" style={{ width: 'auto', height: 'auto' }}>
                        <span style={{ width: '3px', height: '30px' }}></span>
                        <span style={{ width: '3px', height: '30px' }}></span>
                        <span style={{ width: '3px', height: '30px' }}></span>
                        <span style={{ width: '3px', height: '30px' }}></span>
                        <span style={{ width: '3px', height: '30px' }}></span>
                      </div>
                    </div>
                  )}
                  {isFirstDataSelected ? "CONTINUE" : "SUBSCRIBE NOW"}
                </button>
              </div>
              <div className="d-flex justify-content-center mt-1 mb-3">
                <span className="">Membership Note: 18% GST will be added to the fee on checkoutBenefits</span>
              </div>
              <div className="col-lg-12 text-center">
                <div className="sec-heading">
                  <h2 className="fw-bold">Pricing Question</h2>
                  <div className="radio-buttons pick-plan">
                    {
                      membershipTypes && membershipTypes.map((item: any, idx: any) => (
                        <label className="mx-2">
                          <input type="radio" className="" name="pricingOption" value={item.label}
                            checked={priceType === item.label} onChange={handleRadioChange} />
                          <span className="ps-1">{item.label}</span>
                        </label>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {priceType && <FreeQuestions priceType={priceType} />}
      </div>
      <Footer></Footer>
    </div>
    <Modal show={showMobileAndEmail} onHide={handleCloseMobileAndEmail} size="lg" centered backdrop="static">
      <Modal.Header >
        <div className="d-flex align-items-center">
          <button className="btn" onClick={handleCloseMobileAndEmail}><ArrowBackIcon /></button>
          <span className="ms-3 fs-5 fw-bold">Update Details</span>
        </div>
        <div>
          <button className="btn" onClick={handleCloseMobileAndEmail}><CloseIcon /></button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-3">
          <Col md={6} className="mb-2">
            <label className="mb-2">Mobile</label>
            <input type="number" className={`input-with-icon form-control p-3 ${mobileValidationError ? 'is-invalid' : ''}`}
              style={{ backgroundImage: 'none' }}
              value={mobile}
              onChange={e => { handleMobileNumberChange(e.target.value) }}
              placeholder="Mobile" name="Mobile" />
            {mobileValidationError && <div className="invalid-feedback">Mobile No. required</div>}
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-md full-width btn-theme-light-2 rounded"
          onClick={handleModalSubmit}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
    <Modal show={showOTPDialog} onHide={handleCloseOTPDialog} backdrop="static">
      <div className="d-flex justify-content-end">
        <button className="btn text-danger" onClick={handleCloseOTPDialog}> <HighlightOffIcon /></button>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="card card-ui px-3">
          <div className="text-center">
            {/* <img src="./veeduapp-fevicon.png" width="50px" alt="" /> */}
            <img src={OTPPageLogo} width="50px" alt="" />
          </div>
          <h5 className="m-0 text-center">Mobile phone verification</h5>
          <span className="mobile-text">
            Enter the code we just sent on your mobile phone{' '}
            <b className="text-danger">{displayLastTwoDigits(mobile)}</b>
          </span>
          <div className="d-flex justify-content-center mt-5">
            {otpDigits.map((digit, index) => (
              <input
                key={index}
                type="text"
                className="form-control text-input text-center"
                value={digit}
                maxLength={1}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                ref={(input) => (inputRefs.current[index] = input)}
              />
            ))}
          </div>
          <div className="text-center my-3">
            <span className="d-block mobile-text">
              Not received an OTP?{' '}
              <a
                href="#"
                className={`${isOtpSent ? 'text-muted' : 'text-danger'}`}
                onClick={e => {
                  e.preventDefault();
                  if (!isOtpSent) handleResendOtp();
                }}
                style={{ pointerEvents: isOtpSent ? 'none' : 'auto', opacity: isOtpSent ? 0.5 : 1 }}
              >
                Resend OTP{isOtpSent && ` (${seconds}s)`}
              </a>
            </span>
            <button
              className="font-weight-bold rounded btn btn-danger cursor mt-2"
              onClick={handleVerifyOtp}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <form
      id="sabPaisaForm"
      action="https://stage-securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1"
      method="post"
      style={{ display: 'none' }}>
      <input type="text" name="encData" value={encData} />
      <input type="text" name="clientCode" value={clientCode} />
    </form>
  </>
}

export default VeeduBenefits;