import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./view-plan-status.css";
import { Footer, Header } from "../../../layouts";
import { useNavigate } from 'react-router-dom';
import PropertyLoanApiService from "../../../data/api/services/property-loan/property-loan-api-service";
import { toast } from "react-toastify";
import SmsApiService from "../../../data/api/services/sms/sms-api-service";
import PlanStatusApiService from "../../../data/api/services/planstatus/plan-status-api-service";
import profile from "../../../assets/profile-pic.jpeg"
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ViewPlanStatus = () => {

    const [showPopUp, setShowPopUp] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);

    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [countryCode, setcountryCode] = useState("");
    const [countryData, setCountryData] = useState<any>([]);
    const propertyLoanApiService = new PropertyLoanApiService();
    const smsService: SmsApiService =new SmsApiService
    const planStatusService:PlanStatusApiService=new PlanStatusApiService
    const [mobileNoValidationError, setMobileNoValidationError] = useState(false);
    const [countryValidationError, setCountryValidationError] = useState(false);
    const [otpValidationError, setOtpValidationError] = useState(false);
    const [verifyProcess, setVerifyProcess] = useState(false);
    const [sendOtpProcess, setSendOtpProcess] = useState(false);
    const [otpVerifyProcess, setOtpVerifyProcess] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [activeTab, setActiveTab] = useState("Details");

    const [timer, setTimer] = useState(30);
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [data, setData] = useState<any>({});


    const handleShow = () => setShowPopUp(true);
    const handleHide = () => setShowPopUp(false);


    const handleCountryChange = (code: any) => {
        setcountryCode(code);
        setCountryValidationError(false);
    };

    const handleClose = () => {
        setShowPopUp(false);
        navigate(-1);
    };
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
    const handleMobileNumberChange = (value: any) => {
        const formattedValue = value.replace(/\D/g, '').slice(0, 10);
        setMobileNumber(formattedValue);
        setMobileNoValidationError(false);
    };
    const handleEnteredOtp = (value: any) => {
        setOtp(value);
        setOtpValidationError(false);
    };
    const handleVerifyOtp = () => {
        if (validateOtp()) {
            setVerifyProcess(true);
            setOtpValidationError(false);
            if (generatedOtp == otp) {
                toast.success("OTP Verified Successfully", { containerId: 'TR' });
                setVerifyProcess(false);
                handleHide();
                setOtpVerifyProcess(true);
            } else {
                toast.error("Invalid OTP", { containerId: 'TR' });
                setVerifyProcess(false);
            }
        }

    }
    const generateOtp = async () => {
        try {
            let data = await smsService.generateOtp(mobileNumber);
            setGeneratedOtp(data.content);
            console.log(data.content);
            if (data.status == "Success") {
                toast.success("Otp send ...", { containerId: 'TR' });

            } else {
                setLoading(false);
                toast.error("Try Again...", { containerId: 'TR' });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };
  
    const checkPlanStatusByMobileNumber = async () => {
        try {
            let res = await planStatusService.checkPlanStatusByMobileNumber(mobileNumber);
            generateOtp();
            setData(res);
            console.log('getPropertyLoanByMobileNo', res);
        } catch (err: any) {
            setSendOtpProcess(false);
            toast.error(err.response.data.message, { containerId: 'TR' });
        }
    }

    const handleSendOtp = async () => {
        if (validateMobile()) {
            if (mobileNumber.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
            } else {
                try {
                    setSendOtpProcess(true);
                    setTimer(30);
                    checkPlanStatusByMobileNumber();
                } catch (err: any) {
                    toast.error(err.message, { containerId: 'TR' });
                }
            }
        }
    }

    const validateMobile = () => {
        let isFormValid = true;

        if (!mobileNumber) {
            setMobileNoValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    }

    const validateOtp = () => {
        let isFormValid = true;
        if (!otp) {
            setOtpValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    }


    const getTemplatesData = async () => {
        try {
            let res = await propertyLoanApiService.getPropertyLoanTemplatesData();
            console.log(res);
            setCountryData(res.countryData);
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    }
    
    useEffect(() => {
        let intervalId = null;

        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [timer]);

    useEffect(() => {
        handleShow();
        getTemplatesData();

    }, []);

    return <>
    <div>
        <Header></Header>
    </div>
    <Modal size="lg" show={showPopUp} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Verify Your Mobile First</Modal.Title>
        </Modal.Header>
        <div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-2 mt-2">
                                <label>Mobile No.</label>
                            </div>
                            <div className="col-sm-4">
                                <input type="number" className={`input-value form-control ${mobileNoValidationError ? 'is-invalid' : ''}`}
                                    value={mobileNumber}
                                    onChange={e => { handleMobileNumberChange(e.target.value) }} placeholder="Phone number" name="password_confirmation" />
                                {mobileNoValidationError && <div className="invalid-feedback">Mobile No. required</div>}
                            </div>
                            <div className="col-sm-3 mt-1">
                                {!sendOtpProcess && <button className="btn btn-success" type="button"
                                    onClick={handleSendOtp}>Send OTP</button>}
                                {sendOtpProcess && <button className="btn btn-success" type="button"
                                    onClick={handleSendOtp} disabled={timer > 0}>ReSend OTP</button>
                                }

                                <div className="col-sm- mt-3">
                                    {sendOtpProcess && <label> Resend OTP in {timer}s </label>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {sendOtpProcess && <div className="modal-body">
            <div className="row">
                <div className="row">
                    <div className="col-sm-2 mt-2">
                        <label>Enter OTP.</label>
                    </div>
                    <div className="col-sm-3">
                        <input type="number" value={otp}
                            className={`input-value form-control ${otpValidationError ? 'is-invalid' : ''}`}
                            onChange={e => { handleEnteredOtp(e.target.value) }} placeholder="OTP" />
                        {otpValidationError && <div className="invalid-feedback">Please Enter the OTP </div>}
                    </div>
                    <div className="col-sm-3 mt-1">
                        <button disabled={verifyProcess} className="btn btn-success" type="button" onClick={handleVerifyOtp}>Verify</button>
                    </div>
                </div>
            </div>
        </div>}

        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
    {otpVerifyProcess &&

<div className="image-cover hero-banner">
  <div className="container">
    <div className="row p-3 justify-content-center align-items-center">
      <div className="col-sm-6 text-center">
        <h1>Your Plan Status</h1>
        <div className="col-sm-12 text-start">
  {data.length > 0 ? (
    <ul className="id-list">
      {data.map((item) => (
        <li key={item.id} className="id-button-container">
          <button className="id-button">ID: {item.id}</button>
          <div className="col-sm-12 col-md-8 col-lg-12 mx-auto">
          <div className="card custom-card my-3">
  <div className="card-body">
  
    <div className="row" style={{ position: "relative" }}>
      <div
        className={`col-2 text-center ${activeTab === "Details" ? "active-tab" : ""}`}
        onClick={() => setActiveTab("Details")}
      >
        <label style={{ color: "grey", fontSize: "18px", cursor: "pointer" }}>Details</label>
      </div>
      <div
        className={`col-2 text-center ${activeTab === "Discuss" ? "active-tab" : ""}`}
        onClick={() => setActiveTab("Discuss")}
      >
        <label style={{ color: "grey", fontSize: "18px", cursor: "pointer" }}>Discuss</label>
      </div>
      <div
        className={`col-2 text-center ${activeTab === "Download" ? "active-tab" : ""}`}
        onClick={() => setActiveTab("Download")}
      >
        <label style={{ color: "grey", fontSize: "18px", cursor: "pointer" }}>Download</label>
      </div>
    </div>

   
    <div
      className="underline"
      style={{
        height: "4px",
        width: "calc(16.66%)",
        backgroundColor: "lightgreen",
        transition: "transform 0.3s ease",
        transform: `translateX(${activeTab === "Details" ? "0%" : activeTab === "Discuss" ? "100%" : "200%"})`,
      }}
    ></div>

    {activeTab === "Details" && (
      <>
        <div className="row mt-3">
          <div className="col-sm-4">
            <label style={{ color: "grey", fontSize: "18px" }}>Name: {item.name}</label>
          </div>
          <div className="col-sm-4">
            <label style={{ color: "grey", fontSize: "18px" }}>Door Facing: {item.doorFacing}</label>
          </div>
          <div className="col-sm-4">
            <label style={{ color: "grey", fontSize: "18px" }}>Building Dimension: {item.buildingWidthFeet}*{item.buildingHeightFeet}</label>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-sm-4">
            <label style={{ color: "grey", fontSize: "18px" }}>Mobile No: {item.whatsappNumber}</label>
          </div>
          <div className="col-sm-4">
            <label style={{ color: "grey", fontSize: "18px" }}>Plot Facing: {item.plotFacing}</label>
          </div>
          <div className="col-sm-4">
  {(item.plotWidthFeet || item.plotHeightFeet) && (
    <label style={{ color: "grey", fontSize: "18px" }}>
      Plot Dimension: {item.plotWidthFeet}×{item.plotHeightFeet}
    </label>
  )}
</div>

        </div>

        <hr />
        <div className="col-sm-12 text-start">
          <h3>Current Status</h3>
          <label style={{ color: 'grey' }}>18 November 2024</label>
          <div>
            <button className="progess">
              <span
                style={{
                  color: '#007bff', // Primary blue color
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                IN PROGRESS
              </span>
            </button>
            <div className="mt-2">
              <label style={{ color: 'rgb(115 190 214)', fontSize: '18px', marginLeft: '26px' }}>View All Status</label>
            </div>
          </div>
        </div>

        <hr />
        <div className="col-sm-12 text-start">
          <h3>Payment Details</h3>
          <div>
            <button className="progess">
              <span
                style={{
                  color: '#007bff', // Primary blue color
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                Not Paid
              </span>
            </button>
            <div
              className="mt-2 d-flex justify-content-between align-items-center"
             
            >
              <button className="payment">
                <span
                  style={{
                    color: 'grey',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Basic Amount 1750-/-
                </span>
              </button>

              <button className="paynow">
                <span
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
             Pay Now
                </span>
              </button>

          
            </div>
          </div>
        </div>

        <hr />
        <div className="col-sm-12 text-start">
          <h3>Assigned To</h3>
          <div
            className="d-flex align-items-center mt-2"
            style={{ gap: '10px' }} // Adds spacing between elements
          >
            {/* Image */}
            <img
              src={profile}
              alt="Buildup"
              style={{ width: '50px', height: '50px', borderRadius: '50%' }} // Rounded image
            />

            <div>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                EzhilArasu
              </p>
              <p
                style={{
                  margin: 0,
                  color: 'grey',
                  fontSize: '15px',
                }}
              >
                Mobile No: 9087654321
              </p>
            </div>

            <div>
              <button className="progess">
                <span
                  style={{
                    color: '#f89d77', // Primary blue color
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Architect
                </span>
              </button>
            </div>

            <div>
              <FontAwesomeIcon
                icon={faPen}
                style={{
                  color: 'grey',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
              />
            </div>
            <div>
              <FontAwesomeIcon
                icon={faTrash}
                style={{
                  color: 'grey',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 d-flex justify-content-end mt-2">
  <button className="download">
    <span
      style={{
        color: 'grey',
        fontWeight: 'bold',
        fontSize: '20px',
      }}
    >
      Download final copy
    </span>
  </button>
</div>

      </>
    )}
  </div>
</div>

</div>

        </li>
      ))}
    </ul>
  ) : (
    <p>No plan status found</p>
  )}
</div>

      </div>
    </div>
  </div>
</div>




}
</>

};
export default ViewPlanStatus;