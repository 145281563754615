import { Fragment, useEffect, useState } from "react";
import { Footer, Header } from "../../layouts";
import ProfessionBio from "./profession-bio/profession-bio";
import ProfessionPost from "./profession-post/profession-post";
import "./view-profession-profile-container.css";
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import { useLocation } from "react-router-dom";
import CoverImage from "../../assets/coverImage.jpeg"
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Col, Modal, Row } from "react-bootstrap";
import UploadProfileImage from "./upload-profile-image/upload-profile-image";
import StorageService from "../../data/storage/storage-service";
import { Helmet } from 'react-helmet';

const ViewProfessionProfileContainer = () => {

    const professionalPageApiService = new ProfessionalPageApiService();
    const [data, setData] = useState<any>([]);
    const location = useLocation();
    const encoded = new URLSearchParams(location.search).get('v');
    const decoded = atob(encoded);
    const params = new URLSearchParams(decoded);
    const professionalPageId = params.get('id');
    // const professionalPageId = new URLSearchParams(location.search).get('id');
    const [loading, setLoading] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find((item) => item.activePage === true);
    const userPageId = activePageItem?.id;

    const getProfessionalPageById = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageById(professionalPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    };

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            if (userId) {
                let res = await professionalPageApiService.getProfessionalPageData(userId);
                setUserPageData(res);
                getProfessionalPageById();
            } else {
                getProfessionalPageById();
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        // getProfessionalPageById();
        getProfessionalPageDetails();
    }, [professionalPageId]);

    return <>
        <Helmet>
            <title>{data.name}</title>
            <meta name="title" content={data.name} />
            <meta name="description" content={data.aboutUs ? data.aboutUs : ''} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={data.name} />
            <meta property="og:description" content={data.aboutUs ? data.aboutUs : ''} />
            <meta property="og:image" content={data.coverImageURL} />
            {/* <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={window.location.href} />
            <meta property="twitter:title" content={data.name} />
            <meta property="twitter:description" content={data.aboutUs} />
            <meta property="twitter:image" content={data.coverImageURL} /> */}
        </Helmet>
        <Header></Header>
        {loading ? (
            <div className="d-flex justify-content-center"
                style={{ background: 'white', position: 'fixed', top: '50%', bottom: '50%', left: '50%', right: '50%' }}>
                <div className="loading">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        ) : (
            <>
                <section className="container-fluid"
                    style={{ backgroundColor: '#e9e6e6' }}>
                    <div className="row">
                        {userPageId != data.id ? (
                            <img className="banner-image-professional"
                                src={data.coverImageURL ? data.coverImageURL : CoverImage} alt="" />
                        ) : (
                            <div className="col-lg-12 page-size d-flex justify-content-end">
                                <img className="banner-image-professional"
                                    src={data.coverImageURL ? data.coverImageURL : CoverImage} alt=""
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)} />
                                {hovered && (
                                    <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-2"
                                        style={{ borderRadius: '10px' }}
                                        onMouseEnter={() => setHovered(true)}
                                        onMouseLeave={() => setHovered(false)}
                                        onClick={handleShow}>
                                        <CameraAltIcon className="" />
                                        <span className="d-none d-md-block ms-2">Change Cover</span>
                                    </div>
                                )}
                            </div>
                        )}
                        <Col lg={12}>
                            <Row className="justify-content-center" >
                                <Col lg={3}>
                                    <ProfessionBio id={parseInt(professionalPageId)} />
                                </Col>
                                <Col lg={7}>
                                    <ProfessionPost id={parseInt(professionalPageId)} />
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </section>
                <Footer></Footer>
            </>
        )}
        <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
            <UploadProfileImage pageId={data.id} pageData={data} imageType={"COVER_IMAGE"} handleClose={handleClose} />
        </Modal>
    </>
}

export default ViewProfessionProfileContainer;