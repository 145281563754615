import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select from 'react-select';
import { FaSearch } from 'react-icons/fa';
import ProfessionalPageApiService from "../../../../data/api/services/professional-page/professional-page-api-service";
import ValidationError from "../../../../common/validation-error";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import FormFieldError from "../../../../components/form-field-error/form-field-error";
import { Col, Row, Spinner } from "react-bootstrap";
import ProfessionalPageServiceImage from '../../../../assets/professional-page-4.jpeg';

const AddProfessionalPageService = ({ professionalPageFormData, onPreviousCallback, onNextCallback }) => {
    const tabNumber: number = 4;
    const [showNext, setShowNext] = useState(false);
    const [selectedProfessionTypeIdStr, setselectedProfessionTypeIdStr] = useState<any>([]);
    const professionalPAgeApiservice = new ProfessionalPageApiService();
    const [categoryData, setCategoryData] = useState([]);
    const [serviceCategorySearchLoading, setServiceCategorySearchLoading] = useState(false);
    const [serviceCategoryData, setServiceCategoryData] = useState([]);
    const [selectedServiceCategory, setselectedServiceCategory] = useState([]);
    const [asyncTypeaheadKey, setAsyncTypeaheadKey] = useState(0);
    const [dataaa, setData] = useState([]);
    const [filteredCategoryData, setFilteredCategoryData] = useState([]);
    const [professionTypeValidationErrorMsg, setProfessionTypeValidationErrorMsg] = useState(null);
    const [pageServiceValidationErrorMsg, setPageServiceValidationErrorMsg] = useState(null);
    const [processing, setProcessing] = useState(false);

    const handleSelectedProfessionType = (spt: any) => {
        setselectedServiceCategory([]);
        setselectedProfessionTypeIdStr(spt);
        getBusiness(spt.id);
        setAsyncTypeaheadKey((prevKey) => prevKey + 1);
        if (spt) {
            setProfessionTypeValidationErrorMsg(null);
        }
    };

    const preview = () => {
        onPreviousCallback(tabNumber - 1);
        setShowNext(false);
    };

    const searchCategory = async () => {
        let data = await professionalPAgeApiservice.getAllProfessionType();
        setCategoryData(data);
    };

    const getBusiness = async (professionId) => {
        try {
            let res = await professionalPAgeApiservice.getProfessionalPageServicesData(professionId);
            setServiceCategoryData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const filterOption = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    const validateAndSaveCategoryFormData = () => {
        try {
            validateCategoryFormData();
            if (professionalPageFormData.has('professionTypeIdStr')) {
                professionalPageFormData.delete('professionTypeIdStr');
            }
            professionalPageFormData.append('professionTypeIdStr', selectedProfessionTypeIdStr.id);

            if (professionalPageFormData.has('pageServicesStr')) {
                professionalPageFormData.delete('pageServicesStr');
            }
            professionalPageFormData.append('pageServicesStr', JSON.stringify(selectedServiceCategory));
        } catch (error: any) {
            throw error;
        }
    };

    const validateCategoryFormData = (): void => {
        let errors: any[] = [];
        const selectedServiceCategoryData = selectedServiceCategory;
        if (selectedProfessionTypeIdStr.length === 0) {
            let error: any = {};
            error.validationCode = "professionType";
            error.message = 'Profession Type required';
            errors.push(error);
        }
        // if (selectedServiceCategoryData.length === 0) {
        //     let error: any = {};
        //     error.validationCode = "serviceCategory";
        //     error.message = 'Service Category data required';
        //     errors.push(error);
        // }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    };

    const submitServiceFormData = async () => {
        try {
            validateAndSaveCategoryFormData();
            onNextCallback(tabNumber + 1);
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    };

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;
            if (errorCode === 'professionType') {
                setProfessionTypeValidationErrorMsg(errorMsg);
            }
            // if (errorCode === 'serviceCategory') {
            //     setPageServiceValidationErrorMsg(errorMsg);
            // }
        }
    };

    const highlightPageCategoryFormData = async () => {
        let formData: FormData = professionalPageFormData;

        if (formData.get('professionTypeIdStr')) {
            const professionTypeId = formData.get('professionTypeIdStr').toString();
            const getProfessionalType = await professionalPAgeApiservice.getProfessionTypeById(professionTypeId);
            setselectedProfessionTypeIdStr({ id: professionTypeId, label: getProfessionalType.label });
        }

        if (!formData.has('pageServicesStr')) {
            return;
        }
        let serviceCategoryData: any[] = JSON.parse(formData.get('pageServicesStr').toString());
        setselectedServiceCategory([...serviceCategoryData]);
    };

    const onServiceCategorySelected = (selected: any) => {
        setselectedServiceCategory(selected);
        setPageServiceValidationErrorMsg('');
    };

    const getTemplateData = async () => {
        let data = await professionalPAgeApiservice.getProfessionalPageTemplateData();
    };

    const searchServiceCategories = (query) => {
        setServiceCategorySearchLoading(true);
        setTimeout(() => {
            // const filteredOptions = serviceCategoryData.filter((category) =>
            //     category.serviceName.toLowerCase().includes(query.toLowerCase())
            // );
            let filteredOptions;
            if (query.trim() === "") {
                filteredOptions = serviceCategoryData;
            } else {
                filteredOptions = serviceCategoryData.filter((category) =>
                    category.serviceName.toLowerCase().includes(query.toLowerCase())
                );
            }
            setFilteredCategoryData(filteredOptions);
            setServiceCategorySearchLoading(false);
        }, 1000);
    };

    const removeCategory = (category: { id: any }) => {
        setselectedServiceCategory((prevCategories: any[]) =>
            prevCategories.filter((preCategory) => preCategory.id !== category.id)
        );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getTemplateData();
        highlightPageCategoryFormData();
        searchCategory();
    }, []);

    return (
        <>
            <section className="d-flex justify-content-center pb-0">
                <Row className="container">
                    <Col lg={6} className="d-none d-lg-flex align-self-center">
                        <img src={ProfessionalPageServiceImage} className='h-100 w-100' alt="google-bussiness" ></img>
                    </Col>
                    <Col lg={6} className="mt-3">
                        <Row className="g-3">
                            <Col lg={12} className="">
                                <h3 className="text-center">Choose the category that fits your business best</h3>
                            </Col>
                            <Col lg={12} className="">
                                <span className='fs-5'>This helps customers find you if they are looking for a
                                    business like yours.</span>
                            </Col>
                            <Col lg={12}>
                                <div className={`custom-input-field ${professionTypeValidationErrorMsg ? 'is-invalid' : ''}`}>
                                    <select
                                        className="form-control"
                                        value={selectedProfessionTypeIdStr?.label || ""}
                                        onChange={(e) => {
                                            const selectedData = categoryData.find(ptData => ptData.label === e.target.value);
                                            handleSelectedProfessionType(selectedData);
                                        }}
                                    >
                                        <option value="" disabled hidden>Select</option>
                                        {categoryData.map((ptData, index) => (
                                            <option key={ptData.id} value={ptData.label}>
                                                {ptData.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="fw-bold custom-input-field-placeholder">Your Profession</span>
                                </div>
                                {professionTypeValidationErrorMsg && (
                                    <div className="invalid-feedback px-2">Profession Type required</div>
                                )}
                            </Col>
                            <Col lg={12}>
                                <div className="span-font my-2">
                                    <label className="">What Service is your business based in ?</label>
                                </div>
                                <div>
                                    <Col lg={12} className="my-2">
                                        <AsyncTypeahead
                                            key={asyncTypeaheadKey}
                                            className={`custom-typeahead ${pageServiceValidationErrorMsg ? 'error-field input-edit' : ''
                                                }`}
                                            id="service-category-search"
                                            labelKey="serviceName"
                                            onSearch={searchServiceCategories}
                                            onChange={onServiceCategorySelected}
                                            options={filteredCategoryData}
                                            isLoading={serviceCategorySearchLoading}
                                            placeholder="Search Service Categories (Optional)"
                                            multiple
                                            useCache={true}
                                            minLength={0}
                                            onFocus={() => searchServiceCategories('')}
                                        />
                                        <FormFieldError errorMessage={pageServiceValidationErrorMsg} />
                                    </Col>
                                    {selectedServiceCategory.length > 0 &&
                                        selectedServiceCategory.map((category) => (
                                            <div key={category.id} className="location-chip"
                                                style={{ display: 'inline-block' }}>
                                                {category.serviceName}
                                                <span
                                                    className="remove-icon"
                                                    onClick={() => removeCategory(category)}
                                                >
                                                    x
                                                </span>
                                            </div>
                                        ))}
                                    <hr className="divider-line" />
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <div>
                                        <button
                                            disabled={processing}
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={preview}
                                        >
                                            Previous
                                        </button>
                                        {processing && <Spinner animation="grow" variant="warning" className="ms-2" />}
                                    </div>
                                    <div>
                                        <button
                                            disabled={processing}
                                            className="btn btn-success"
                                            type="button"
                                            onClick={submitServiceFormData}
                                        >
                                            Next
                                        </button>
                                        {processing && <Spinner animation="grow" variant="warning" className="ms-2" />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </>
    );
};

export default AddProfessionalPageService;
