import React, { useEffect, useState } from "react";
import "./profession-bio.css";
import PlaceMarker from "../../../assets/place-marker.png";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import { Link, useNavigate } from "react-router-dom";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import { faEdit, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Modal, Row } from "react-bootstrap";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Avatar from "../../../assets/profile-pic.jpeg";
import AddProfessionPost from "../add-profession-post/add-profession-post";
import ProfessionPostShare from "../profession-post-share/profession-post-share";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadProfileImage from "../upload-profile-image/upload-profile-image";

const ProfessionBio = ({ id }: { id: number }) => {
  const professionalPageApiService = new ProfessionalPageApiService();
  const [data, setData] = useState<any>({});
  const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
  let navigate: any = useNavigate();
  const [hasEditOption, setHasEditOption] = useState(false);
  const professionalPageId = id;
  const [userPageData, setUserPageData] = useState<any>([]);
  const activePageItem = userPageData.find((item) => item.activePage === true);
  const userPageId = activePageItem?.id;
  const [displayedImages, setDisplayedImages] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [shareData, setShareData] = useState<any>([]);
  const [shareShow, setShareShow] = useState(false);
  const shareClose = () => setShareShow(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hovered, setHovered] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getMediaCount = () => {
    let count = 0;
    if (Array.isArray(professionalPagePosts)) {
      count = professionalPagePosts.reduce((totalImages, post) => {
        if (Array.isArray(post.professionalPagePostImageGalleryData)) {
          const imagesWithPaths = post.professionalPagePostImageGalleryData.filter(
            (item) => item.imagePath
          );
          totalImages += imagesWithPaths.length;
        }
        return totalImages;
      }, 0);
    }
    return count;
  };

  const mediaCount = getMediaCount();

  const getProfessionalPageDetails = async () => {
    try {
      const userId = StorageService.getUserId();
      if (userId) {
        let res = await professionalPageApiService.getProfessionalPageData(userId);
        setUserPageData(res);
        const activePageItem = res.find((item) => item.activePage === true);
        const userPageId = activePageItem?.id;
        getProfessionalPagById(userPageId);
      } else {
        getProfessionalPagById("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessionalPagById = async (userPageId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPageById(id);
      const editOptionExists = res.id === userPageId;
      setHasEditOption(editOptionExists);
      setData(res);
      const normalPosts = res.professionalPagePosts.filter(
        (post) => post.postType === "NORMAL_POST"
      );
      setProfessionalPagePosts(normalPosts);
      const allImages = normalPosts.flatMap((post) => post.professionalPagePostImageGalleryData);
      setDisplayedImages(allImages.slice(0, 9));
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageClick = (imageData: any) => {
    // navigate(`/view-profession-image`, {
    //   state: { data, selectedImage: imageData, professionalPagePosts, professionalPageId },
    // });
    const combined = `pageId=${professionalPageId}&mediaId=${imageData.id}`;
    const encoded = btoa(combined);
    navigate(`/view-media?v=${encoded}`);
  };

  useEffect(() => {
    getProfessionalPageDetails();
  }, [professionalPageId]);

  return (
    <>
      <div className="card card-view">
        {userPageId != data.id ? (
          <img
            className="card_profile_img"
            style={{ background: "#f3f1f1" }}
            height={130} width={130}
            src={data.profileImageUrl ? data.profileImageUrl : Avatar}
            alt="avatar"
          />
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <img
              className="card_profile_img"
              style={{ background: "#f3f1f1" }}
              height={130} width={130}
              src={data.profileImageUrl ? data.profileImageUrl : Avatar}
              alt="avatar"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            />
            {hovered && (
              <div className="d-flex align-items-center justify-content-center video-play-icon"
                onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}
                style={{ marginTop: '-60px' }}>
                <CameraAltIcon className="m-2" style={{ fontSize: 'xx-large' }}
                  onClick={handleShow} />
              </div>
            )}
          </div>
        )}
        <div className="user_details text-center">
          <div className="row mb-2 mt-2">
            <span className="name">{data.name}</span>
            {data.nickName !== "null" && <span>{data.nickName}</span>}
            <span>{data.professionTypeLabel}</span>
            <span>⭐️⭐️⭐️⭐️⭐️</span>
            {data.aboutUs !== "null" && (
              <div>
                <span className={`about-us ${isExpanded ? 'expanded' : ''}`}>{data.aboutUs}</span>
                {!isExpanded && data.aboutUs && (
                  <button className="read-more-btn" onClick={toggleReadMore}>
                    Read More
                  </button>
                )}
                {isExpanded && (
                  <button className="read-less-btn" onClick={toggleReadMore}>
                    Read Less
                  </button>
                )}
              </div>
            )}
            <span className="email mb-3">{data.email}</span>
            {data.locationName == 0 || data.locationName == null ? null :
              <span className="d-flex justify-content-center">
                <img className="place-marker" src={PlaceMarker} alt="Place Marker" />
                {data.locationName}, {data.pinCode}
              </span>
            }
          </div>
        </div>
        <hr />
        <div className="text-center">
          {data.professionalPageServiceMappers && data.professionalPageServiceMappers.length > 0 && (
            <>
              <Col>
                <div className="bg-light p-1" style={{ borderRadius: "8px" }}>
                  {Array.isArray(data.professionalPageServiceMappers) &&
                    data.professionalPageServiceMappers.map((item, index) => (
                      <div className="d-flex align-items-center justify-content-center" key={index}>
                        <span>•</span>
                        <label className="ms-1"> {item.serviceName}</label>
                      </div>
                    ))}
                </div>
              </Col>
              <label className="my-1">More services ...</label> <br />
            </>
          )}
          <label className="my-1">
            <WallpaperIcon /> {mediaCount} Photos and Videos
          </label>
          <Row className="">
            {Array.isArray(displayedImages) &&
              displayedImages.map((imageData: any, imageIndex: number) => (
                <Col md={4} xs={4} key={imageIndex} className="p-2">
                  {displayedImages.length >= 9 && imageIndex === 8 ? (
                    <div style={{ position: "relative" }} className="d-flex align-items-center justify-content-center " onClick={() => handleImageClick(imageData)}>
                      {imageData.mimeType === "IMAGE" ? (
                        <img
                          key={imageIndex}
                          className="image-with-border m-0"
                          src={imageData.imagePath}
                          width="100%"
                          // height="55px"
                          style={{ aspectRatio: '2/1' }}
                          alt={`Image ${imageIndex + 1}`}
                        />
                      ) : (
                        <div className="d-flex align-items-center justify-content-center ">
                          <video className="image-with-border m-0" style={{ width: "100%", aspectRatio: "2/1" }}>
                            <source src={imageData.imagePath} />
                          </video>
                        </div>
                      )}
                      {imageIndex === 8 && (
                        <div className="overlay video-play-icon" style={{ borderRadius: "5px" }}>+{mediaCount - 9}</div>
                      )}
                    </div>
                  ) : (
                    <div onClick={() => handleImageClick(imageData)}>
                      {imageData.mimeType === "IMAGE" ? (
                        // <div>
                          <img
                            key={imageIndex}
                            className="image-with-border m-0"
                            src={imageData.imagePath}
                            width="100%"
                            // height="55px"
                            style={{ aspectRatio: '2/1', objectFit: 'cover' }}
                            alt={`Image ${imageIndex + 1}`}
                          />
                        // </div>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center ">
                          <video className="image-with-border m-0" style={{ width: "100%", aspectRatio: "2/1" }}>
                            <source src={imageData.imagePath} />
                          </video>
                          <div className="d-flex align-items-center justify-content-center video-play-icon">
                            <PlayCircleOutlineIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              ))}
          </Row>
          <Modal size="lg" show={shareShow} onHide={shareClose}>
            <ProfessionPostShare data={shareData} handleClose={shareClose} />
          </Modal>
          <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
            <UploadProfileImage pageId={data.id} pageData={data} imageType={"PROFILE_IMAGE"} handleClose={handleClose} />
          </Modal>
        </div>
      </div >
    </>
  );
};


export default ProfessionBio;

