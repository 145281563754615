import "./need-custom-plan.css";
import { Footer, Header } from "../../layouts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import PropertyListContainer from "../property/property-list-page/property-list-container";
import StorageService from "../../data/storage/storage-service";
import jwt_decode from 'jwt-decode';
import { Col, Container, Row, Image, Carousel, Modal, Form, Button, Card } from "react-bootstrap";
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

import save from "../../assets/save.png"
import bed from "../../assets/bhk.png"
import bath from "../../assets/plotbath.png"
import garage from "../../assets/garages.png"
import square from "../../assets/sqft.png"
import building from "../../assets/plotbuilding.png"
import plan from "../../assets/customplan.png"
import download from "../../assets/Download.png"
import door from "../../assets/door.png"
import plot from "../../assets/Plot.png"
import downloadblack from "../../assets/downloadblack.jpeg"
import photo from "../../assets/photo.jpeg"
import specification from "../../assets/specification.jpeg"
import youtube from "../../assets/youtube.jpeg"
import threeplan from "../../assets/threeplan.jpeg"
import twoplan from "../../assets/twoplan.jpeg"
import tick from "../../assets/tick.png"

import buildup from "../../assets/buildup.png"
import landarea from "../../assets/landarea.png"
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import React from "react";
import CustomPlan from "./custom-plan/custom-plan";
import PlanStatusApiService from "../../data/api/services/planstatus/plan-status-api-service";
import { FavouritePlanPayload } from "../../data/api/services/planstatus/plan-status-payload";
import LoginPageDialog from "../login/login-page-dialog";

const NeedCustomPlan = () => {

  let navigate: any = useNavigate();
  const location = useLocation();
  const encoded = new URLSearchParams(location.search).get('v');
  const decoded = atob(encoded);
  const params = new URLSearchParams(decoded);
  const planId = params.get('planId');
  const doorFacingId = params.get('doorFacingId');
  const [userPageData, setUserPageData] = useState<any>([]);
  const activePageItem = userPageData.find(item => item.activePage === true);
  const userPageId = activePageItem?.id;
  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const handleShowLogInDialog = () => setShowLogInDialog(true);
  const handleCloseLogInDialog = () => setShowLogInDialog(false);
  const [data, setData] = useState<any>([]);
  const userId = StorageService.getUserId();

  const [relatedPlanId, setRelatedPlanId] = useState(null); 
  const [show, setShow] = useState(false);
  const [threeDShow, setthreeDShow] = useState(false);
  const [ytShow, setytShow] = useState(false);
  const [specificationShow, setSpecificationShow] = useState(false);
  const [photoShow, setPhotoShow] = useState(false);
  const [isTurnedIn, setIsTurnedIn] = useState(false);

  const handleShow = () => setShow(true);
  const handlethreeDShow = () => setthreeDShow(true);
  const handleytShow = () => setytShow(true);
  const handleSpecificationShow = () => setSpecificationShow(true);
  const handlePhotoShow = () => setPhotoShow(true);

  const handleClose = () => setShow(false);
  const handlethreeDClose = () => setthreeDShow(false);
  const handleytClose = () => setytShow(false);
  const handleSpecificationClose = () => setSpecificationShow(false);
  const handlePhotoClose = () => setPhotoShow(false);

  const [selectedItem, setSelectedItem] = useState('Photos');
  const [totalRecord, setTotalRecord] = useState(0);
  const [newPlan, setNewPlan] = useState<any>([]);
  const [recentPlan, setRecentPlan] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [RelatedcurrentPage, setRelatedCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100000);

  const [RealtedpageSize, setRelatedPageSize] = useState(100000);
  const userType = StorageService.getUserType();
   const [expandedItems, setExpandedItems] = useState({});
   const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);

   const handleIconClick = async (id) => {
    const userType = StorageService.getUserType();
  
    if (!userType) {
      handleShowLogInDialog();
    } else {
      try {
        let payload = {
          userPageId: userPageId, 
          addNewPlanId: id,
        };
  
        await planStatusApiService.saveFavouritePlan(payload);
        getPageData(currentPage, pageSize);

      } catch (error) {
        console.log("Error saving favourite plan:", error);
        toast.error("Failed to save the plan.");
      }
    }
  };
  
  
  const getProfessionalPageDetails = async () => {
    try {
        const userId = StorageService.getUserId();
        console.log(userId);
        let res = await professionalPageApiService.getProfessionalPageData(userId);
        setUserPageData(res);
        const activePageItem = res.find(item => item.activePage === true);
        const userPageId = activePageItem?.id;
        getProfessionalPageById(userPageId);
        getPageData(currentPage, pageSize);


    } catch (error) {
        console.log(error);
    }
};
  
const getProfessionalPageById = async (userPageId) => {
  try {
      let res = await professionalPageApiService.getProfessionalPageById(userPageId);
      setData(res);
  } catch (error) {
      console.log(error);
  }
};
const getProfessionalPagePostDataList = async () => {
  try {
      let res = await professionalPageApiService.getProfessionalPageData(userId);

      setProfessionalPagePosts(res);
      console.log(res);
  } catch (error) {
      console.log(error);
  }
};
  const handleReadMoreToggle = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const handleClick = async (id) => {
    setRelatedPlanId(id); // Set the planId
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };
  
  useEffect(() => {
    getProfessionalPageDetails();
    getProfessionalPagePostDataList();
}, []);
  const handlethreeDCopyLink = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].twoDPlan) {
      console.error('No link available to copy');
      return;
    }
    toast.success('Your three D plan  Link Copied ', { containerId: 'TR' });
    navigator.clipboard.writeText(newPlan[0].threeDPlan)
      .then(() => {
        toast.success('Copied your 2D Plan!');
      })
      .catch(err => {
        toast.error('Failed to copy link.');
      });
  };
  const handleCopyLink = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].twoDPlan) {
      console.error('No link available to copy');
      return;
    }
    toast.success('Your two D plan  Link Copied ', { containerId: 'TR' });
    navigator.clipboard.writeText(newPlan[0].twoDPlan)
      .then(() => {
        toast.success('Copied your 2D Plan!');
      })
      .catch(err => {
        toast.error('Failed to copy link.');
      });
  };
  const handleSpecificationLink = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].specificationPdf) {
      console.error('No link available to copy');
      return;
    }
    toast.success('Your specification Link Copied ', { containerId: 'TR' });
    navigator.clipboard.writeText(newPlan[0].specificationPdf)
      .then(() => {
        toast.success('Copied your 2D Plan!');
      })
      .catch(err => {
        toast.error('Failed to copy link.');
      });
  };
  const handleytCopyLink = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].ytVideoLink) {
      console.error('No link available to copy');
      return;
    }
    toast.success('Your Youtube Video  Link Copied ', { containerId: 'TR' });
    navigator.clipboard.writeText(newPlan[0].ytVideoLink)
      .then(() => {
        toast.success('Copied your Youtube Video!');
      })
      .catch(err => {
        toast.error('Failed to copy link.');
      });
  };
  const handlePhotoCopyLink = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].photo) {
      console.error('No link available to copy');
      return;
    }
    toast.success('Your photo Link Copied ', { containerId: 'TR' });
    navigator.clipboard.writeText(newPlan[0].photo)
      .then(() => {
        toast.success('Copied your photo!');
      })
      .catch(err => {
        toast.error('Failed to copy link.');
      });
  };
  const truncateDescription = (description, isExpanded) => {
    const words = description.split(' ');
    if (words.length > 20 && !isExpanded) {
      return words.slice(0, 20).join(' ') + '...';
    }
    return description;
  };
  const extractYouTubeVideoID = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  };
  const handleDownload = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].twoDPlan) {
      console.error('No file available to download');
      return;
    }

    const fileUrl = newPlan[0].twoDPlan;

    if (fileUrl.includes('drive.google.com')) {
      // Open the Google Drive link in a new tab for download
      window.open(fileUrl, '_blank');
      toast.success('Your 2D plan Downloaded sucessfully', { containerId: 'TR' });
    } else {
      // Handle other file URLs with fetch
      toast.success('Your 2D plan Downloaded sucessfully', { containerId: 'TR' });

      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'floor-plan.pdf'; // Set desired filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error('Download error:', error);
        });
    }
  };


  const handleytDownload = () => {
    if (!newPlan || !newPlan[0] || !newPlan[0].ytVideoLink) {
      console.error('No file available to download');
      return;
    }

    const ytVideoLink = newPlan[0].ytVideoLink;

    // Check if it's a YouTube link
    if (ytVideoLink.includes('youtube.com') || ytVideoLink.includes('youtu.be')) {
      toast.success('Opening YouTube Video', { containerId: 'TR' });

      // Open the YouTube video in a new tab
      window.open(ytVideoLink, '_blank');
    } else {
      toast.success('your youtube video downloading sucessfully', { containerId: 'TR' });

      const link = document.createElement('a');
      link.href = ytVideoLink; // Use the link as it is
      link.download = 'floor-plan.pdf'; // Set a specific name for the downloaded file

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
    }
  };

  const handlethreeDDownload = () => {

    if (!newPlan || !newPlan[0] || !newPlan[0].threeDPlan) {
      console.error('No file available to download');
      return;
    }

    const threeDPlan = newPlan[0].threeDPlan;

    // Check if it's a YouTube link
    if (threeDPlan.includes('youtube.com') || threeDPlan.includes('youtu.be')) {
      toast.success('Opening YouTube Video', { containerId: 'TR' });

      // Open the YouTube video in a new tab
      window.open(threeDPlan, '_blank');
    } else {
      toast.success('your threeDPlan downloading sucessfully', { containerId: 'TR' });

      const link = document.createElement('a');
      link.href = threeDPlan; // Use the link as it is
      link.download = 'floor-plan.pdf'; // Set a specific name for the downloaded file

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
    }
  };
  const handlespecificationDownload = () => {


    if (!newPlan || !newPlan[0] || !newPlan[0].specificationPdf) {
      console.error('No file available to download');
      return;
    }

    const fileUrl = newPlan[0].specificationPdf;

    // Check if the URL is a Google Drive link
    if (fileUrl.includes('drive.google.com')) {
      // Open the Google Drive link in a new tab for download
      window.open(fileUrl, '_blank');
      toast.success('Your specificationPdf Downloaded sucessfully', { containerId: 'TR' });
    } else {
      // Handle other file URLs with fetch
      toast.success('Your specificationPdf Downloaded sucessfully', { containerId: 'TR' });

      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'floor-plan.pdf'; // Set desired filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error('Download error:', error);
        });
    }
  };
  const handlephotoDownload = () => {

    if (!newPlan || !newPlan[0] || !newPlan[0].photo) {
      console.error('No file available to download');
      return;
    }

    const fileUrl = newPlan[0].photo;

    if (fileUrl.includes('drive.google.com')) {
      window.open(fileUrl, '_blank');
      toast.success('Your photo Downloaded sucessfully', { containerId: 'TR' });
    } else {
      toast.success('Your photo Downloaded sucessfully', { containerId: 'TR' });

      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'floor-plan.pdf'; // Set desired filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error('Download error:', error);
        });
    }
  };
  const videoUrl = newPlan?.[0]?.videoUrl;
  const videoID = videoUrl ? extractYouTubeVideoID(videoUrl) : null;
  const embedUrl = videoID ? `https://www.youtube.com/embed/${videoID}` : null;
  const getPageData = async (page, size) => {
    try {
      let url = `?page=${page}&pageSize=${size}`;

      if (relatedPlanId) {
        url += `&id=${relatedPlanId}`;
      } else if (planId) {
        url += `&id=${planId}`;
      }

      let result = await professionalPageApiService.getAllnewPlanData(url);
      console.log("Data fetched:", result);
      setNewPlan(result.data);
      setTotalRecord(result.totalRecord);
    } catch (error) {
      console.log("Error fetching data");
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, { containerId: 'TR' });
        StorageService.clear();
        navigate('/login');
      } else if (error.request) {
        toast.error('Error: No response received from the server');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  const getReleatedPlanData = async (page, size) => {
    try {
      let url = `?page=${page}&pageSize=${size}&doorFacingId=${doorFacingId}`;
      if (relatedPlanId) {
        url += `&RealtedId=${relatedPlanId}`;
      } else if (planId) {
        url += `&RealtedId=${planId}`;
      }
      let result = await professionalPageApiService.getAllnewPlanData(url);
      console.log("recent", result);
      setRecentPlan(result.data);
      setTotalRecord(result.totalRecord);
    } catch (error) {
      console.log("poperty search Error");
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.message, { containerId: 'TR' });
        StorageService.clear();
        navigate('/login');
      } else if (error.request) {
        toast.error('Error: No response received from the server');
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  }

  const isTokenExpired = (token: any) => {
    interface DecodedToken {
      sub: string;
      exp: number;
      iat: number;
    }
    const decodedToken: DecodedToken = jwt_decode(token);
    console.log('token decodedToken', decodedToken);
    const currentDate = new Date();
    return (decodedToken.exp < currentDate.getTime() / 1000);
  };


  const [youtubeUrl, setYoutubeUrl] = useState('');
 
  const professionalPageApiService = new ProfessionalPageApiService();
  const planStatusApiService = new PlanStatusApiService();



  useEffect(() => {

    getPageData(currentPage, pageSize);

  }, [planId, relatedPlanId]);
  useEffect(() => {

    getReleatedPlanData(RelatedcurrentPage, RealtedpageSize);

  }, [relatedPlanId]);
  const handleYoutubeURLOnChange = (value: any) => {
    setYoutubeUrl(value)
  };

 
  const onTagClick = async (id: number) => {

    navigate(`/view-profession-post-tag?&tagId=${id}`, { state: {} });
    console.log(`View button clicked for tagId: ${id}`);
  };
  const [colVisible, setColVisible] = useState(true);
  const [colSize, setColSize] = useState(12);
  const [rightSideVisible, setRightSideVisible] = useState(true);
  const [animationEffect, setAnimationEffect] = useState("");
  const [leftSideAnimationEffect, setLeftSideAnimationEffect] = useState("");

  const toggleColVisibility = () => {
    setColVisible(!colVisible);
    setColSize(colVisible ? 11 : 12);
    setRightSideVisible(!rightSideVisible);
    setAnimationEffect(!colVisible ? "slide-return" : "slide-center");
    setLeftSideAnimationEffect(!rightSideVisible ? "slide-left" : "")
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const privacyDiv = document.querySelector('.privacy');
    if (window.innerWidth > 576) {
      if (privacyDiv) {
        const privacyDivTopInView = privacyDiv.getBoundingClientRect().top;
        if (privacyDivTopInView < 0 && colVisible) {
          toggleColVisibility();
        }
      }
      const scrollPosition = window.scrollY;
      if (scrollPosition === 0 && !privacyDiv) {
        setColVisible(true);
        setColSize(colVisible ? 12 : 11);
        setRightSideVisible(rightSideVisible);
        setAnimationEffect(!colVisible ? "slide-center" : "slide-return");
        setLeftSideAnimationEffect(!rightSideVisible ? "" : "slide-left")
      }
    }
  };
  const housePlanId = newPlan && newPlan.length > 0 ? newPlan[0].id : 'N/A';

  return <>

    <div>
      <Header />
    </div>
    <Container className="mt-5 pt-1">
      <Row className="pt-5 d-flex justify-content-center">


        <Col sm={6}>
          <Container className="d-flex justify-content-between align-items-center" style={{ height: '78px' }}>
            <Row className="m-1">
              <Col xs={2} sm={1} className="p-0">
                <div className="vertical-line"></div>
              </Col>
              <Col xs={10} sm={11} className="p-0">
                {newPlan && newPlan.length > 0 ? (
                  newPlan.map((item) => (
                    <div key={item.id} className="d-flex align-items-center mb-2">
                      <label className="title-label">{item.title}</label>
                    </div>
                  ))
                ) : (
                  <p>No plans available</p>
                )}
              </Col>
            </Row>
          </Container>
          <ul className="privacy mt-0 container justify-content-between d-flex pt-0 px-4">
            <li className="d-flex me-0">
              <li className={selectedItem === 'Photos' ? 'active' : ''} onClick={() => setSelectedItem('Photos')}>Photos</li>
              <li className={selectedItem === 'Videos' ? 'active' : ''} onClick={() => setSelectedItem('Videos')}>Video</li>
              <li className={selectedItem === 'Downloads' ? 'active' : ''} onClick={() => setSelectedItem('Downloads')}>Download</li>

            </li>
            <li className="d-flex me-0">
              <span className="house-id-label">ID:</span>
              <span className="house-id" style={{ marginLeft: '5px' }}>
                {newPlan && newPlan.length > 0 ? newPlan[0].id : 'N/A'}
              </span>
            </li>

          </ul>

          <hr className="hr-margin" />
       
          {selectedItem && newPlan && newPlan.length > 0 && selectedItem !== 'Downloads' && (
  <div className="card" style={{ width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
    {selectedItem === 'Photos' && (
      <Carousel>
        {newPlan[0].coverImageURL && (
          <Carousel.Item>
            <div className="position-relative">
              <img
                src={newPlan[0].coverImageURL}
                className="d-block w-100 card-img-top"
                alt="Cover"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '10px',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  overflow: 'hidden',
                }}
                onClick={() => handleIconClick(newPlan[0].id)}
              >
                {newPlan[0].newPlanFavouriteData.some(fav => fav.userPageId === userPageId) ? (
                  <TurnedInIcon style={{ width: '40px', height: '40px', color: 'red' }} />
                ) : (
                  <TurnedInNotIcon style={{ width: '40px', height: '40px', color: 'red' }} />
                )}
              </div>
            </div>
          </Carousel.Item>
        )}
        {newPlan[0].newPlanImageGalleryData &&
          newPlan[0].newPlanImageGalleryData.map((image) => (
            <Carousel.Item key={image.id}>
              <div className="position-relative">
                <img
                  src={image.imagePath}
                  className="d-block w-100 card-img-top"
                  alt={image.name}
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    overflow: 'hidden',
                  }}
                  onClick={() => handleIconClick(newPlan[0].id)}
                >
                  {newPlan[0].newPlanFavouriteData.some(fav => fav.userPageId === userPageId) ? (
                    <TurnedInIcon style={{ width: '40px', height: '40px', color: 'red' }} />
                  ) : (
                    <TurnedInNotIcon style={{ width: '40px', height: '40px', color: 'red' }} />
                  )}
                </div>
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
    )}

    {selectedItem === 'Videos' && embedUrl && (
      <div className="video-container" style={{ width: '100%' }}>
        <div className="embed-responsive embed-responsive-16by9" style={{ height: '300px', borderRadius: '8px', overflow: 'hidden' }}>
          <iframe
            src={embedUrl}
            title="Video Tour"
            className="embed-responsive-item"
            allowFullScreen
            style={{ width: '100%', height: '100%', borderRadius: '8px' }}
          />
        </div>
      </div>
    )}
  </div>
)}

       {selectedItem === 'Downloads' && (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
    {newPlan[0].twoDPlan ? (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: 'lightgreen',
        padding: '10px',
        borderRadius: '8px',
        width: '100%',
        maxWidth: '400px',
        boxSizing: 'border-box'
      }}>
        <div style={{ flex: '1', minWidth: '50px' }}>
          <img src={twoplan} alt="Two Plan" style={{ width: '50px', height: '50px' }} />
        </div>
        <div style={{ flex: '2', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>2D Floor Plan</div>
          <div style={{ fontSize: '12px', color: 'black' }}>
            File Format: {newPlan[0].twoDPlanLinkType} ₹{newPlan[0].twoDPlanAmount}
          </div>
        </div>
        <div style={{ flex: '1', textAlign: 'center' }}>
          <button
            style={{
              width: '100px',
              height: '40px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleShow}
          >
            Buy Now
          </button>
        </div>
      </div>
    ) : null}

    {/* ThreeD Plan */}
    {newPlan[0].threeDPlan ? (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: 'yellow',
        padding: '10px',
        borderRadius: '8px',
        width: '100%',
        maxWidth: '400px',
        boxSizing: 'border-box'
      }}>
        <div style={{ flex: '1', minWidth: '50px' }}>
          <img src={threeplan} alt="Three Plan" style={{ width: '50px', height: '50px' }} />
        </div>
        <div style={{ flex: '2', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>3D Floor Plan</div>
          <div style={{ fontSize: '12px', color: 'black' }}>
            File Format: {newPlan[0].threeDPlanLinkType} ₹{newPlan[0].threeDPlanAmount}
          </div>
        </div>
        <div style={{ flex: '1', textAlign: 'center' }}>
          <button
            style={{
              width: '100px',
              height: '40px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handlethreeDShow}
          >
            Buy Now
          </button>
        </div>
      </div>
    ) : null}

    {/* YouTube Video */}
    {newPlan[0].ytVideoLink ? (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', backgroundColor: 'lightcoral', padding: '10px', borderRadius: '8px', width: '100%', maxWidth: '400px' }}>
        <div style={{ flex: '1' }}>
          <img src={youtube} alt="YouTube" style={{ width: '50px', height: '50px' }} />
        </div>
        <div style={{ flex: '2', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Video</div>
          <div style={{ fontSize: '12px', color: 'black' }}>
            File Format: {newPlan[0].ytVideoLinkType} ₹{newPlan[0].ytVideoLinkAmount}
          </div>
        </div>
        <div style={{ flex: '1', textAlign: 'center' }}>
          <button
            style={{
              width: '100px',
              height: '40px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleytShow}
          >
            Buy Now
          </button>
        </div>
      </div>
    ) : null}

    {/* Specification Pdf */}
    {newPlan[0].specificationPdf ? (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', backgroundColor: '#bebeda', padding: '10px', borderRadius: '8px', width: '100%', maxWidth: '400px' }}>
        <div style={{ flex: '1' }}>
          <img src={specification} alt="Specification" style={{ width: '50px', height: '50px' }} />
        </div>
        <div style={{ flex: '2', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ fontSize: '18', fontWeight: 'bold' }}>Specification</div>
          <div style={{ fontSize: '12px', color: 'black' }}>
            File Format: {newPlan[0].specificationPdfLink} ₹{newPlan[0].specificationPdfAmount}
          </div>
        </div>
        <div style={{ flex: '1', textAlign: 'center' }}>
          <button
            style={{
              width: '100px',
              height: '40px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleSpecificationShow}
          >
            Buy Now
          </button>
        </div>
      </div>
    ) : null}

    {/* Photos */}
    {newPlan[0].photo ? (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', backgroundColor: 'lightblue', padding: '10px', borderRadius: '8px', width: '100%', maxWidth: '400px' }}>
        <div style={{ flex: '1' }}>
          <img src={photo} alt="Photo" style={{ width: '50px', height: '50px' }} />
        </div>
        <div style={{ flex: '2', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>Photos</div>
          <div style={{ fontSize: '12px', color: 'gray' }}>
            File Format: {newPlan[0].photoLink} ₹{newPlan[0].photoAmount}
          </div>
        </div>
        <div style={{ flex: '1', textAlign: 'center' }}>
          <button
            style={{
              width: '100px',
              height: '40px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handlePhotoShow}
          >
            Buy Now
          </button>
        </div>
      </div>
    ) : null}

    
  </div>
)}
 






          {selectedItem !== 'Downloads'&& (
            <div
              className="mt-1"
              style={{
                backgroundColor: '#FFE5B4',
                borderRadius: '3px',
                padding: '8px',
                margin: 'auto',
                maxWidth: '100%',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
                <Link to="/customplan">
                  <img
                    src={plan}
                    alt="Plan"
                    style={{ width: '30px', height: '30px' }}
                  />
                </Link>

                <p
                  style={{ margin: 0, fontSize: '16px', cursor: 'pointer' }}
                  onClick={() => navigate('/customplan')}
                >
                  Customize
                </p>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: 'auto', flexWrap: 'wrap' }}>
          

                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <img
                      src={download}
                      alt="Download"
                      style={{ width: '25px', height: '25px' }}
                    />
                    <p style={{ margin: 0, fontSize: '16px' }}>Download Floor Plan</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedItem !== 'Downloads' &&  (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <div
                className="vertical-line"
                style={{ marginRight: '10px' }}
              ></div>
              <span style={{ fontSize: '20px', color: 'black' }}>Plan Details</span>
            </div>
          )}
          {selectedItem !== 'Downloads' && (
            <Row className="mt-3 g-3">
              {/* Build Up Area Card */}
              <Col xs={6} lg={3} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      maxWidth: '300px', // Limit max width of the card
                      minHeight: '160px',
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                      overflow: 'hidden', // Ensure content does not overflow
                    }}
                  >
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <img
                          src={buildup}
                          alt="Build Area"
                          style={{ width: '80px', height: '80px' }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span style={{ fontSize: '18px', textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          BuildUpArea
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span
                          style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#0fca98',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis', // Add text overflow handling
                          }}
                        >
                          {`${item.buildingWidthFeet || 0}' ${item.buildingWidthInches || 0}" x ${item.buildingHeightFeet || 0}' ${item.buildingHeightInches || 0}"`}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-center">
                        <span style={{ fontSize: '14px', textAlign: 'center' }}>
                          ('feet' "inches")
                        </span>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>

              {/* Land Area Card */}
              <Col xs={6} lg={3} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      maxWidth: '300px', // Limit max width of the card
                      minHeight: '160px',
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                      overflow: 'hidden',
                    }}
                  >
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <img
                          src={landarea}
                          alt="Land Area"
                          style={{ width: '95px', height: '95px' }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span style={{ fontSize: '17px', textAlign: 'center' }}>
                          LandArea
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span
                          style={{
                            fontSize: '17px',
                            textAlign: 'center',
                            color: 'black',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {`${item.plotWidthFeet || 0}' ${item.plotWidthInches || 0}" x ${item.plotHeightFeet || 0}' ${item.plotHeightInches || 0}"`}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-center">
                        <span style={{ fontSize: '14px', textAlign: 'center' }}>
                          ('feet' "inches")
                        </span>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>

              {/* Door Card */}
              <Col xs={6} lg={3} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      maxWidth: '300px', // Limit max width of the card
                      minHeight: '160px',
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                      overflow: 'hidden',
                    }}
                  >
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <img
                          src={door}
                          alt="Door"
                          style={{ width: '80px', height: '80px' }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span style={{ fontSize: '20px', textAlign: 'center' }}>
                          Door
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span
                          style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#429fbe',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {`${item.doorFacing || 0}`}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-center">
                        {/* Empty row for spacing or additional content */}
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>

              {/* Plot Card */}
              <Col xs={6} lg={3} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      maxWidth: '300px', // Limit max width of the card
                      minHeight: '160px',
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                      overflow: 'hidden',
                    }}
                  >
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <img
                          src={plot}
                          alt="Plot"
                          style={{ width: '80px', height: '80px' }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span style={{ fontSize: '20px', textAlign: 'center' }}>
                          Plot
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12} className="d-flex justify-content-center">
                        <span
                          style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            color: '#429fbe',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {`${item.plotFacing || 0}`}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-center">
                        {/* Empty row for spacing or additional content */}
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
            </Row>


          )}
          {selectedItem !== 'Downloads' && (
            <Row className="mt-3 g-3">

              <Col xl={4} lg={6} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      minHeight: '80px', 
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                    <div style={{ flex: '0 0 25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={bed} alt="Build Area" style={{ width: '60px', height: '60px' }} /> {/* Adjusted image size */}
                    </div>
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <p style={{ margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}>
                        Bedroom(s) <span style={{ color: 'rgb(15, 202, 152)', fontSize: '20px' }}>{item.bedRoom}</span> {/* Green color for item.garage */}
                      </p>          </div>
                  </div>
                ))}
              </Col>

              <Col xl={4} lg={6} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      minHeight: '80px', 
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                    <div style={{ flex: '0 0 25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={bath} alt="Land Area" style={{ width: '54px', height: '60px' }} /> {/* Adjusted image size */}
                    </div>
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <p style={{ margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}>
                        Bathroom(s) <span style={{ color: 'rgb(15, 202, 152)', fontSize: '20px' }}>{item.bathRoom}</span> {/* Green color for item.garage */}
                      </p>        </div>
                  </div>
                ))}
              </Col>

              <Col xl={4} lg={6} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      minHeight: '80px', 
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                
                    <div style={{ flex: '0 0 25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={garage} alt="Door" style={{ width: '60px', height: '60px' }} /> {/* Adjusted image size */}
                    </div>
                   
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <p style={{ margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}>
                        Garages <span style={{ color: 'rgb(15, 202, 152)', fontSize: '14px' }}>{item.garage}</span> {/* Green color for item.garage */}
                      </p>
                    </div>
                  </div>
                ))}
              </Col>


     
              <Col xl={4} lg={6} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      minHeight: '80px', // Decreased height
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                    {/* Image Column */}
                    <div style={{ flex: '0 0 25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={square} alt="Plot" style={{ width: '58px', height: '60px' }} /> {/* Adjusted image size */}
                    </div>
                    {/* Content Column */}
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <p style={{ margin: 0, fontSize: '14px' }}>{item.totalSqFt}sqft</p> {/* Adjusted font size */}
                    </div>
                  </div>
                ))}
              </Col>

              {/* Building Card */}
              <Col xl={4} lg={6} className="d-flex justify-content-center">
                {newPlan.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      width: '100%',
                      minHeight: '80px', // Decreased height
                      boxSizing: 'border-box',
                      backgroundColor: '#f3f3f3',
                    }}
                  >
                    {/* Image Column */}
                    <div style={{ flex: '0 0 25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img src={building} alt="Building" style={{ width: '60px', height: '60px' }} /> {/* Adjusted image size */}
                    </div>
                    {/* Content Column */}
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <p style={{ margin: 0, fontSize: '14px', whiteSpace: 'nowrap' }}>{item.houseModel}</p> {/* Adjusted font size */}
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          )}
          {(selectedItem === 'Photos' || selectedItem === 'Videos') && (
            <div className="mt-3" style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ backgroundColor: 'rgb(15, 202, 152)', color: 'white' }}
                className="btn"
                onClick={() => setSelectedItem('Downloads')}
              >
                Download Floor Plan
              </button>
            </div>
          )}

          {selectedItem !== 'Downloads' && (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
              <div
                className="vertical-line"
                style={{ marginRight: '10px' }}
              ></div>
              <span style={{ fontSize: '20px', color: 'black' }}>Plan Description</span>
            </div>
          )}


          {selectedItem !== 'Downloads' && (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
              {newPlan.map((item) => {
                const isExpanded = expandedItems[item.id] || false;
                return (
                  <div key={item.id} style={{ marginBottom: '10px' }}>
                    <p>
                      {truncateDescription(item.description, isExpanded)}
                      {item.description.split(' ').length > 20 && !isExpanded && (
                        <span
                          onClick={() => handleReadMoreToggle(item.id)}
                          style={{ color: '#4dc9d4', cursor: 'pointer', marginLeft: '5px' }}
                        >
                          Read More
                        </span>
                      )}
                      {isExpanded && (
                        <span
                          onClick={() => handleReadMoreToggle(item.id)}
                          style={{ color: '#4dc9d4', cursor: 'pointer', marginLeft: '5px' }}
                        >
                          Show Less
                        </span>
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          )}

          {selectedItem !== 'Downloads' && (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
              <span style={{ color: 'black', fontSize: '18px' }}>
                Tags:
                {newPlan && newPlan.length > 0 && newPlan[0].professionalPagePostTagData ? (
                  newPlan[0].professionalPagePostTagData.map((tag, index) => (
                    <React.Fragment key={tag.id}>
                      {index > 0 && <span style={{ color: 'black', fontSize: '18px', fontWeight: 'bold' }}>.</span>}
                      <span
                        style={{ color: '#4dc9d4', fontSize: '16px', marginLeft: index > 0 ? '5px' : '10px', cursor: 'pointer' }}
                        onClick={() => onTagClick(tag.id)}
                      >
                        {tag.tagName}
                      </span>
                    </React.Fragment>
                  ))
                ) : (
                  <span>No tags available</span>
                )}
              </span>
            </div>
          )}

          <div>

            {selectedItem !== 'Downloads' && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <div
                  className="vertical-line"
                  style={{ marginRight: '10px' }}
                ></div>
                <span style={{ fontSize: '20px', color: 'black' }}>Related House Model</span>
              </div>
            )}
            {selectedItem !== 'Downloads' && (
              <Row className="mt-3 pt-1">
                {recentPlan.length > 0 ? (
                  recentPlan.map((plan) => (
                    <Col md={12} lg={6} key={plan.id} className="mb-4">
                      <Card>
                        {plan.coverImageURL && (
                          <Card.Img
                            className="equal-height-img"
                            variant="top"
                            src={plan.coverImageURL}
                          />
                        )}
                        <Card.Body style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                          <Card.Title>{plan.title}</Card.Title>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                            <span>Id:{plan.id}</span>
                            <button
                              style={{
                                backgroundColor: 'yellow',
                                border: 'none',
                                borderRadius: '50px',
                                padding: '5px 14px',
                                cursor: 'pointer'
                              }}
                              onClick={() => handleClick(plan.id)}
                            >
                              ViewDetails
                            </button>
                          </div>
                        </Card.Body>



                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col>
                    <p>No Related Plan available</p>
                  </Col>
                )}
              </Row>
            )}

          </div>









        </Col>




      </Row>


    </Container >
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title className="custom-modal-title">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-auto custom-icon-container">
            <div >
              <img src={tick} alt="Tick" className="custom-icon-img" />
            </div>
          </div>

          <div className="col-8">
            <div>
              <span style={{ fontSize: '19px', fontWeight: 'bold' }}>Thank You for Your Purchase!</span>
            </div>



            <div >
              <span style={{ fontSize: '16px', color: 'grey' }}>Your payment has beensuccessfully processed,and 2D floor plan is ready for download</span> {/* Added text */}
            </div>


          </div>

        </div>

        <div className="mt-3 text-center">
          <span style={{ fontSize: '18px', color: 'black' }}>Download Instructions :</span>
        </div>
        <div className="mt-2" >
          <span style={{ fontSize: '16px', color: 'grey' }}>To acess your plan,please click the button below:</span> {/* Added text */}
        </div>
        <div className="button-container">
          <button className="grey-button" onClick={handleDownload}>
            <div className="button-content">
              <span className="button-text">Download 2D Floor Plan</span>
              <img src={download} alt="Tick" className="download-icon-img" />
            </div>
          </button>
        </div>
        <div className="mt-3 text-center">
          <span
            style={{ fontSize: '18px', color: '669bac', cursor: 'pointer' }}
            onClick={handleCopyLink}
          >
            Copy Link
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            If you have any questions or need assistance, please do not hesitate to contact our support team at
            <span style={{ color: 'black' }}> ezhilarasu007@gmail.com </span>
            or call us at  <span style={{ color: 'black' }}> +91 9486060448</span> .
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            we appreciate your business and hope you enjoy your new floor plan.have a great day !
          </span>
        </div>

        <div className="button-container">
          <button className="green-button" onClick={handleClose}>
            Back to the app !
          </button>
        </div>
      </Modal.Body>


    </Modal>
    <Modal show={threeDShow} onHide={handlethreeDClose}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title className="custom-modal-title">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {/* First Column: Circular Tick Icon */}
          <div className="col-auto custom-icon-container">
            <div >
              <img src={tick} alt="Tick" className="custom-icon-img" />
            </div>
          </div>

          {/* Second Column: Label */}
          <div className="col-8">
            <div>
              <span style={{ fontSize: '19px', fontWeight: 'bold' }}>Thank You for Your Purchase!</span>
            </div>



            <div >
              <span style={{ fontSize: '16px', color: 'grey' }}>Your payment has beensuccessfully processed,and 3D floor plan is ready for download</span> {/* Added text */}
            </div>


          </div>

        </div>

        <div className="mt-3 text-center">
          <span style={{ fontSize: '18px', color: 'black' }}>Download Instructions :</span>
        </div>
        <div className="mt-2" >
          <span style={{ fontSize: '16px', color: 'grey' }}>To acess your plan,please click the button below:</span> {/* Added text */}
        </div>
        <div className="button-container">
          <button className="grey-button" onClick={handlethreeDDownload}>
            <div className="button-content">
              <span className="button-text">Download 3D Floor Plan</span>
              <img src={download} alt="Tick" className="download-icon-img" />
            </div>
          </button>
        </div>
        <div className="mt-3 text-center">
          <span
            style={{ fontSize: '18px', color: '669bac', cursor: 'pointer' }}
            onClick={handlethreeDCopyLink}
          >
            Copy Link
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            If you have any questions or need assistance, please do not hesitate to contact our support team at
            <span style={{ color: 'black' }}> ezhilarasu007@gmail.com </span>
            or call us at  <span style={{ color: 'black' }}> +91 9486060448</span> .
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            we appreciate your business and hope you enjoy your new floor plan.have a great day !
          </span>
        </div>

        <div className="button-container">
          <button className="green-button" onClick={handlethreeDClose}>
            Back to the app !
          </button>
        </div>
      </Modal.Body>


    </Modal>
    <Modal show={ytShow} onHide={handleytClose}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title className="custom-modal-title">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {/* First Column: Circular Tick Icon */}
          <div className="col-auto custom-icon-container">
            <div >
              <img src={tick} alt="Tick" className="custom-icon-img" />
            </div>
          </div>

          {/* Second Column: Label */}
          <div className="col-8">
            <div>
              <span style={{ fontSize: '19px', fontWeight: 'bold' }}>Thank You for Your Purchase!</span>
            </div>



            <div >
              <span style={{ fontSize: '16px', color: 'grey' }}>Your payment has beensuccessfully processed,and Video is ready for download</span> {/* Added text */}
            </div>


          </div>

        </div>

        <div className="mt-3 text-center">
          <span style={{ fontSize: '18px', color: 'black' }}>Download Instructions :</span>
        </div>
        <div className="mt-2" >
          <span style={{ fontSize: '16px', color: 'grey' }}>To acess your Video,please click the button below:</span> {/* Added text */}
        </div>
        <div className="button-container">
          <button className="grey-button" onClick={handleytDownload}>
            <div className="button-content">
              <span className="button-text">Download Video</span>
              <img src={download} alt="Tick" className="download-icon-img" />
            </div>
          </button>
        </div>
        <div className="mt-3 text-center">
          <span
            style={{ fontSize: '18px', color: '669bac', cursor: 'pointer' }}
            onClick={handleytCopyLink}
          >
            Copy Link
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            If you have any questions or need assistance, please do not hesitate to contact our support team at
            <span style={{ color: 'black' }}> ezhilarasu007@gmail.com </span>
            or call us at  <span style={{ color: 'black' }}> +91 9486060448</span> .
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            we appreciate your business and hope you enjoy your new floor plan.have a great day !
          </span>
        </div>

        <div className="button-container">
          <button className="green-button" onClick={handleytClose}>
            Back to the app !
          </button>
        </div>
      </Modal.Body>


    </Modal>
    <Modal show={specificationShow} onHide={handleSpecificationClose}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title className="custom-modal-title">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {/* First Column: Circular Tick Icon */}
          <div className="col-auto custom-icon-container">
            <div >
              <img src={tick} alt="Tick" className="custom-icon-img" />
            </div>
          </div>

          {/* Second Column: Label */}
          <div className="col-8">
            <div>
              <span style={{ fontSize: '19px', fontWeight: 'bold' }}>Thank You for Your Purchase!</span>
            </div>



            <div >
              <span style={{ fontSize: '16px', color: 'grey' }}>Your payment has beensuccessfully processed,and Specification is ready for download</span> {/* Added text */}
            </div>


          </div>

        </div>

        <div className="mt-3 text-center">
          <span style={{ fontSize: '18px', color: 'black' }}>Download Instructions :</span>
        </div>
        <div className="mt-2" >
          <span style={{ fontSize: '16px', color: 'grey' }}>To acess your Specification,please click the button below:</span> {/* Added text */}
        </div>
        <div className="button-container">
          <button className="grey-button" onClick={handlespecificationDownload}>
            <div className="button-content">
              <span className="button-text">Download Specification </span>
              <img src={download} alt="Tick" className="download-icon-img" />
            </div>
          </button>
        </div>
        <div className="mt-3 text-center">
          <span
            style={{ fontSize: '18px', color: '669bac', cursor: 'pointer' }}
            onClick={handleSpecificationLink}
          >
            Copy Link
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            If you have any questions or need assistance, please do not hesitate to contact our support team at
            <span style={{ color: 'black' }}> ezhilarasu007@gmail.com </span>
            or call us at  <span style={{ color: 'black' }}> +91 9486060448</span> .
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            we appreciate your business and hope you enjoy your new floor plan.have a great day !
          </span>
        </div>

        <div className="button-container">
          <button className="green-button" onClick={handleSpecificationClose}>
            Back to the app !
          </button>
        </div>
      </Modal.Body>


    </Modal>
    <Modal show={photoShow} onHide={handlePhotoClose}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title className="custom-modal-title">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {/* First Column: Circular Tick Icon */}
          <div className="col-auto custom-icon-container">
            <div >
              <img src={tick} alt="Tick" className="custom-icon-img" />
            </div>
          </div>

          {/* Second Column: Label */}
          <div className="col-8">
            <div>
              <span style={{ fontSize: '19px', fontWeight: 'bold' }}>Thank You for Your Purchase!</span>
            </div>



            <div >
              <span style={{ fontSize: '16px', color: 'grey' }}>Your payment has beensuccessfully processed,and Photo is ready for download</span> {/* Added text */}
            </div>


          </div>

        </div>

        <div className="mt-3 text-center">
          <span style={{ fontSize: '18px', color: 'black' }}>Download Instructions :</span>
        </div>
        <div className="mt-2" >
          <span style={{ fontSize: '16px', color: 'grey' }}>To acess your Photo,please click the button below:</span> {/* Added text */}
        </div>
        <div className="button-container">
          <button className="grey-button" onClick={handlephotoDownload}>
            <div className="button-content">
              <span className="button-text">Download Photo </span>
              <img src={download} alt="Tick" className="download-icon-img" />
            </div>
          </button>
        </div>
        <div className="mt-3 text-center">
          <span
            style={{ fontSize: '18px', color: '669bac', cursor: 'pointer' }}
            onClick={handlePhotoCopyLink}
          >
            Copy Link
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            If you have any questions or need assistance, please do not hesitate to contact our support team at
            <span style={{ color: 'black' }}> ezhilarasu007@gmail.com </span>
            or call us at  <span style={{ color: 'black' }}> +91 9486060448</span> .
          </span>
        </div>
        <div className="mt-2">
          <span style={{ fontSize: '16px', color: 'grey' }}>
            we appreciate your business and hope you enjoy your new floor plan.have a great day !
          </span>
        </div>

        <div className="button-container">
          <button className="green-button" onClick={handlePhotoClose}>
            Back to the app !
          </button>
        </div>
      </Modal.Body>


    </Modal>
    <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
                centered className="login-page-dialog-modal">
                <LoginPageDialog handleClose={handleCloseLogInDialog} />
            </Modal>


  </>
}
export default NeedCustomPlan;