import { useEffect, useState } from 'react';
import './update-professional-page-container.css'
import { Footer, Header } from '../../../layouts';
import EditProfessioanlPageBasicinfo from './edit-professional-page-basic-info/edit-professional-page-basic-info';
import EditProfessionalPageWorkHour from './edit-professionalpage-workhour/edit-professional-page-work-hour';
import EditProfessionalPageServiceArea from './edit-professional-page-service-area/edit-professional-page-service-area';
import EditProfessionalPageService from './edit-professionalpage-service/edit-professionalpage-service';
import EditProfessionalPageContactInfo from './edit-professionalpage-contact-info/edit-professionalpage-contact-info';
import ProfessionalPageApiService from '../../../data/api/services/professional-page/professional-page-api-service';
import { useLocation, useSearchParams } from 'react-router-dom';
import StorageService from '../../../data/storage/storage-service';

const UpdateProfessionalPageContainer = () => {

    const professionalPageApiservice = new ProfessionalPageApiService();
    // const [searchParams, setSearchParams] = useSearchParams();
    // const professionalPageId = searchParams.get('id');
    const { state } = useLocation();
    const professionalPageId = state?.id;
    const [isLoading, setIsLoading] = useState(true);

    const [activeStep, setActiveStep] = useState(1);
    const [professionalPageFormData, setProfessionalPageFormData] = useState<any>(new FormData());

    const handleNextStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }

    const handlePreviousStepperCallback = (stepperId: number) => {
        refreshSteps(stepperId);
    }

    const refreshSteps = (stepperId: number) => {
        setActiveStep(stepperId);
    }

    // const getProfessionalPageDetails = async () => {
    //     try {
    //         const userId = StorageService.getUserId();
    //         console.log(userId);
    //         let data = await professionalPageApiservice.getProfessionalPageData(userId);
    //         console.log(data);
    //         const professionalPageId = data.id;
    //         setSearchParams({ id: professionalPageId });
    //         highlightEditProfessionalPageData(data);
    //         setIsLoading(false);
    //     } catch (error) {
    //     }
    // }

    const getProfessionalPagById = async () => {
        try {
            let data = await professionalPageApiservice.getProfessionalPageById(professionalPageId);
            highlightEditProfessionalPageData(data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const highlightEditProfessionalPageData = (data: any) => {
        professionalPageFormData.append('name', data.name);
        professionalPageFormData.append('nickName', data.nickName);
        professionalPageFormData.append('aboutUs', data.aboutUs);

        professionalPageFormData.append('email', data.email);
        professionalPageFormData.append('address', data.address);
        professionalPageFormData.append('locationIdStr', data.locationId);
        professionalPageFormData.append('pageWorkHoursStr', JSON.stringify(data.professionalPageWorkHours));
        professionalPageFormData.append('serviceAreasStr', JSON.stringify(data.professionalPageLocationMappers));
        professionalPageFormData.append('professionTypeIdStr', data.professionTypeId);
        professionalPageFormData.append('pageServicesStr', JSON.stringify(data.professionalPageServiceMappers));
        professionalPageFormData.append('countryIdStr', data.countryId);
        professionalPageFormData.append('mobile', data.mobile);
        professionalPageFormData.append('website', data.website);
        professionalPageFormData.append('facebook', data.faceBook);
        professionalPageFormData.append('twitter', data.twitter);
        professionalPageFormData.append('instagram', data.instagram);
        professionalPageFormData.append('youTube', data.youTube);
        professionalPageFormData.append('pinterest', data.pinterest);
    }

    useEffect(() => {
        // getProfessionalPageDetails();
        getProfessionalPagById();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return <>
        <Header></Header>
        {/* <div className="container mt-5"> */}


        {activeStep === 1 && (
            <EditProfessioanlPageBasicinfo professionalPageId={professionalPageId} professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}

        {/* {activeStep != 2 &&   <div className=' header-inpage ' onClick={tab2}>
                <h5 className='p-3  header-style '> Step 2 </h5>
            </div>} */}

        {activeStep === 2 && (
            <EditProfessionalPageWorkHour professionalPageId={professionalPageId} professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}
        {/* {activeStep != 3 &&   <div className=' header-inpage ' onClick={tab3}>
                <h5 className='p-3  header-style '> Step 3 </h5>
            </div>} */}
        {activeStep === 3 && (
            <EditProfessionalPageServiceArea professionalPageId={professionalPageId} professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}
        {/* {activeStep != 4 &&   <div className=' header-inpage ' onClick={tab4}>
                <h5 className='p-3  header-style '> Step 4 </h5>
            </div>} */}
        {activeStep === 4 && (
            <EditProfessionalPageService professionalPageId={professionalPageId} professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} onNextCallback={handleNextStepperCallback} />
        )}
        {/* {activeStep != 5 &&   <div className=' header-inpage ' onClick={tab5}>
                <h5 className='p-3  header-style '> Step 5 </h5>
            </div>} */}
        {activeStep === 5 && (
            <EditProfessionalPageContactInfo professionalPageId={professionalPageId} professionalPageFormData={professionalPageFormData} onPreviousCallback={handlePreviousStepperCallback} />
        )}

        {/* </div> */}
        <div className="d-none d-lg-block">
            <Footer></Footer>
        </div>
    </>
}
export default UpdateProfessionalPageContainer;